import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Card, Form, Nav, ProgressBar, Button } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import GeneralForm from './GeneralForm';
import BusinessForm from './BusinessForm';
import RecordsForm from './RecordsForm';
import { EstimateDataVolumeButton } from './RecordsForm';
import DocumentsForm from './DocumentsForm';
import ControlsForm from './ControlsForm';
import PoliciesForm from './PoliciesForm';
import ReadinessForm from './ReadinessForm';
import CompletedForm from './CompletedForm';
import { useClientWizardContext } from 'providers/ClientWizardProvider';
import IconButton from 'components/common/IconButton';
import Flex from 'components/common/Flex';
import { useAppContext } from 'providers/AppProvider';
import { useNavigate } from 'react-router-dom';
import useSaveIntake from 'hooks/useSaveIntake';
import useIntake from 'hooks/useIntake';
import paths from 'routes/paths';
import {
  faRecycle,
  faCheck,
  faFolderOpen
} from '@fortawesome/free-solid-svg-icons';
import useSaveContact from 'hooks/useSaveContact';
import useGetOneClient from 'hooks/useGetOneClient';
import useSaveClient from 'hooks/useSaveClient';
import useConfirmDialog from 'components/common/ConfirmDialog';

const navItems = [
  {
    icon: 'user',
    label: 'General'
  },
  {
    icon: 'building',
    label: 'Business'
  },
  {
    icon: faFolderOpen,
    label: 'Records'
  },
  {
    icon: 'file',
    label: 'Documents'
  },
  {
    icon: 'key',
    label: 'Controls'
  },
  {
    icon: 'shield-alt',
    label: 'Policies'
  },
  {
    icon: 'thumbs-up',
    label: 'Readiness '
  }
];

const totalSteps = navItems.length;

const WizardLayout = ({ variant, validation, progressBar, operation }) => {
  const { isRTL } = useAppContext();
  const navigate = useNavigate();
  const { client, setClient, intake, setIntake, step, setStep } =
    useClientWizardContext();
  const { clientId } = useParams();
  const { saveIntake } = useSaveIntake(clientId);
  const { fetchIntake } = useIntake(clientId);
  const { updateContact, saveContact } = useSaveContact();
  const { getClient, updateClient } = useGetOneClient();
  const { saveClient } = useSaveClient();
  const [validationError, setValidationError] = useState('');
  const { ConfirmDialog, confirm } = useConfirmDialog();

  const [saveAndClose, setSaveAndClose] = useState(false);

  useEffect(() => {
    const loadIntakeForm = async () => {
      await fetchIntake(setIntake);
    };

    if (operation === 'update') {
      setStep(2);
      loadIntakeForm();
    }
  }, []);

  useEffect(() => {
    const getClientDetails = async clientId => {
      try {
        const clientDetail = await getClient(clientId);

        setClient(clientDetail);
      } catch (error) {
        throw new Error(error.message);
      }
    };

    if (operation === 'update') {
      getClientDetails(clientId);
    }
  }, [clientId]);

  const resetIntake = () => {
    navigate(paths.addClient);
  };

  const intakeDone = () => {
    navigate(paths.clients);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    clearErrors,
    setError,
    control
  } = useForm({
    defaultValues: { ...intake, ...client }
  });

  const handleSaveAndClose = data => {
    saveIntake({
      ...intake,
      ...data
    });
    setSaveAndClose(false);
    navigate(paths.clients);
  };

  const prepareContacts = data => {
    if (!Array.isArray(data.contact) || !data.contact.length) {
      data.contacts = [{}];
    }

    data.contacts[0] = {
      ...data?.contacts[0],
      contactMethod: data.contactMethod,
      name: data.contactName,
      phone: data.contactPhone,
      email: data.contactEmail
    };

    return data;
  };

  const handleNewClient = async (data, updatedClient) => {
    const {
      status,
      error,
      data: newClientData
    } = await saveClient(updatedClient);
    if (status === 200) {
      clearErrors();
      setValidationError('');
      setClient({ ...updatedClient, ...newClientData });

      await saveContact(data, clientId ?? client.id);

      if (
        updatedClient.prospect === 'false' ||
        (await confirm(
          'You added a new prospect. Would you like to continue with the intake?',
          'Prospect Added',
          'Yes',
          'No'
        ))
      ) {
        setStep(step + 1);
      } else {
        navigate(paths.clients);
      }
    } else {
      handleErrorOrValidationError(error);
    }
  };

  const handleExistingClient = async (data, updatedClient) => {
    const { status, error } = await updateClient(
      updatedClient.id,
      updatedClient
    );
    if (status === 200) {
      setValidationError('');

      if (client.contacts[0]?.id) {
        await updateContact(
          data,
          client.contacts[0]?.id ?? '',
          clientId ?? client.id
        );
      } else {
        await saveContact(data, clientId ?? client.id);
      }

      if (
        updatedClient.prospect === 'false' ||
        (await confirm(
          'You added a new prospect. Would you like to continue with the intake?',
          'Prospect Added',
          'Yes',
          'No'
        ))
      ) {
        setStep(step + 1);
      } else {
        navigate(paths.clients);
      }
    } else {
      handleErrorOrValidationError(error);
    }
  };

  const handleStepOne = async data => {
    data = prepareContacts(data);
    const updatedClient = { ...client, ...data };

    setClient(updatedClient);

    if (operation === 'add' && !clientId && !client.id) {
      await handleNewClient(data, updatedClient);
    } else {
      await handleExistingClient(data, updatedClient);
    }
  };

  const handleLastStep = data => {
    data.completed = true;
    setIntake(prevIntake => ({
      ...prevIntake,
      ...data
    }));
    saveIntake(data);
    setStep(step + 1);
    //TODO: Send email here
  };

  const handleDefaultStep = data => {
    data.started = true;
    setIntake(prevIntake => ({
      ...prevIntake,
      ...data
    }));
    setStep(step + 1);
  };

  const handleErrorOrValidationError = error => {
    if (typeof error === 'object' && error.error) {
      handleError(error);
    } else {
      setValidationError(error);
    }
    console.error('API Error:', error);
  };

  const onSubmitData = async data => {
    if (saveAndClose === true) {
      handleSaveAndClose(data);
      return;
    }

    switch (step) {
      case 1:
        await handleStepOne(data);
        break;
      case totalSteps:
        handleLastStep(data);
        break;
      default:
        handleDefaultStep(data);
        break;
    }
  };

  const handleError = error => {
    if (error.error === 'DuplicateClient') {
      if (error.sameName) {
        setError('name', {
          type: 'custom',
          message: 'Client with same name already exist'
        });
      }
      if (error.sameEmail) {
        setError('email', {
          type: 'custom',
          message: 'Client with same e-mail already exist'
        });
      }
      if (error.samePhone) {
        setError('phone', {
          type: 'custom',
          message: 'Client with same phone already exist'
        });
      }
      setStep(1);
    } else {
      setValidationError(error.message);
    }
  };

  const onError = () => {
    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const handleNavs = targetStep => {
    if (step !== totalSteps + 1 && targetStep > 1) {
      if (targetStep < step) {
        setStep(targetStep);
      } else {
        handleSubmit(onSubmitData, onError)();
      }
    }
  };

  return (
    <div className="client-intake">
      <ConfirmDialog />
      <Card
        as={Form}
        noValidate
        onSubmit={handleSubmit(onSubmitData, onError)}
        className="theme-wizard mb-5 p-0"
      >
        <Card.Header
          className={classNames('bg-body-tertiary', {
            'px-4 py-3': variant === 'pills',
            'pb-2': !variant
          })}
        >
          <Nav className="justify-content-center" variant={variant}>
            {variant === 'pills'
              ? navItems.map(
                  (item, index) =>
                    // If operation == update, don't show the first step
                    (index > 0 || operation !== 'update') && (
                      <NavItemPill
                        key={item.label}
                        index={index + 1}
                        step={step}
                        handleNavs={handleNavs}
                        icon={item.icon}
                        label={item.label}
                      />
                    )
                )
              : navItems.map(
                  (item, index) =>
                    // If operation == update, don't show the first step
                    (index > 0 || operation !== 'update') && (
                      <NavItem
                        key={item.label}
                        index={index + 1}
                        step={step}
                        handleNavs={handleNavs}
                        icon={item.icon}
                        label={item.label}
                      />
                    )
                )}
            {step === totalSteps + 1 && <NavItemCompletion step={step} />}
          </Nav>
        </Card.Header>
        {progressBar && <ProgressBar now={step * 20} style={{ height: 2 }} />}
        <Card.Body className="fw-normal px-md-6 py-4 intake-step">
          {step === 1 && (
            <GeneralForm
              register={register}
              errors={errors}
              setValue={setValue}
              client={client}
              control={control}
            />
          )}
          {step === 2 && (
            <BusinessForm
              register={register}
              errors={errors}
              setValue={setValue}
              intake={intake}
            />
          )}
          {step === 3 && (
            <RecordsForm
              register={register}
              errors={errors}
              setValue={setValue}
              intake={intake}
            />
          )}
          {step === 4 && (
            <DocumentsForm
              setValue={setValue}
              control={control}
              intake={intake}
            />
          )}
          {step === 5 && (
            <ControlsForm
              register={register}
              errors={errors}
              setValue={setValue}
              intake={intake}
            />
          )}
          {step === 6 && (
            <PoliciesForm
              setValue={setValue}
              control={control}
              intake={intake}
            />
          )}
          {step === 7 && (
            <ReadinessForm
              register={register}
              errors={errors}
              setValue={setValue}
              intake={intake}
            />
          )}
          {step === 8 && (
            <CompletedForm
              register={register}
              errors={errors}
              setValue={setValue}
              intake={intake}
            />
          )}
          {validationError && <p className="text-danger">{validationError}</p>}
        </Card.Body>
        <Card.Footer className="px-md-6 bg-body-tertiary">
          {step === 3 && (
            <EstimateDataVolumeButton
              setValue={setValue}
              getValues={getValues}
            />
          )}
          <div className="hstack gap-3 d-flex">
            <div className="row w-100">
              <div className="col-6 d-flex align-items-center">
                {step < totalSteps ? (
                  <IconButton
                    variant="link"
                    icon={isRTL ? 'chevron-right' : 'chevron-left'}
                    iconAlign="left"
                    transform="down-1 shrink-4"
                    className={classNames('px-0 p-2 fw-semibold', {
                      'd-none': step <= 2
                    })}
                    onClick={() => {
                      setStep(step - 1);
                    }}
                  >
                    Back
                  </IconButton>
                ) : (
                  step === totalSteps && (
                    <p className="fw-bold"> Nice Job! You're almost done</p>
                  )
                )}
              </div>
              <div className="col-6 d-flex justify-content-end align-items-center">
                {step === totalSteps + 1 ? (
                  <>
                    <Button
                      variant="secondary"
                      className="ps-2 pr-2 m-1"
                      type="submit"
                      transform="down-1 shrink-4"
                      onClick={() => resetIntake()}
                    >
                      <FontAwesomeIcon className="me-2 ms-2" icon={faRecycle} />
                      Reset Form
                    </Button>
                    <Button
                      variant="success"
                      className="ps-2 pr-2 m-1"
                      type="button"
                      transform="down-1 shrink-4"
                      onClick={() => intakeDone()}
                    >
                      <FontAwesomeIcon className="me-2 ms-2" icon={faCheck} />
                      Done
                    </Button>
                  </>
                ) : step !== totalSteps ? (
                  step != 1 && (
                    <Button
                      variant="falcon-primary"
                      className="ps-2 pr-2 m-1"
                      type="submit"
                      transform="down-1 shrink-4"
                      onClick={() => setSaveAndClose(true)}
                    >
                      Save & Close
                    </Button>
                  )
                ) : (
                  <IconButton
                    variant="falcon-primary"
                    icon={isRTL ? 'chevron-right' : 'chevron-left'}
                    iconAlign="left"
                    transform="down-1 shrink-4"
                    className="m-1 ps-2 pe-2"
                    onClick={() => {
                      setStep(step - 1);
                    }}
                  >
                    Back
                  </IconButton>
                )}
                {step === totalSteps ? (
                  <Button
                    variant="primary"
                    className="ps-2 pe-2 m-1"
                    type="submit"
                    transform="down-1 shrink-4"
                  >
                    Submit
                  </Button>
                ) : (
                  step < totalSteps && (
                    <IconButton
                      variant="primary"
                      className="m-1"
                      type="submit"
                      icon={isRTL ? 'chevron-left' : 'chevron-right'}
                      iconAlign="right"
                      transform="down-1 shrink-4"
                    >
                      Next
                    </IconButton>
                  )
                )}{' '}
              </div>
            </div>
          </div>
        </Card.Footer>
      </Card>
    </div>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semibold', {
          done: index < totalSteps + 1 ? step > index : step > 5,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs-10">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semibold', {
          done: step > index,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs-10 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemCompletion = ({ step }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semibold', {
          done: step > 5,
          active: step === totalSteps
        })}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon="check" />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs-10">Completed</span>
      </Nav.Link>
    </Nav.Item>
  );
};

WizardLayout.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool,
  operation: PropTypes.oneOf(['add', 'update'])
};

NavItemPill.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  label: PropTypes.string.isRequired
};

NavItem.propTypes = NavItemPill.propTypes;

NavItemCompletion.propTypes = {
  step: PropTypes.number.isRequired
};

export default WizardLayout;
