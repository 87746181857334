import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const useSaveContact = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const updateContact = (data, contactId, clientId) => {
    if (!clientId) {
      clientId = localStorage.getItem('clientId');
    }
    return makeRequest(
      data,
      `${process.env.REACT_APP_API_SERVER_URL}/clients/${clientId}/contact/${contactId}/update`
    );
  };

  const saveContact = (data, clientId) => {
    if (!clientId) {
      clientId = localStorage.getItem('clientId');
    }
    return makeRequest(
      data,
      `${process.env.REACT_APP_API_SERVER_URL}/clients/${clientId}/add/contact`
    );
  };

  const makeRequest = async (data, url) => {
    setIsLoading(true);
    setError(null);
    const temp = {};
    //extract contact data from client object
    for (const key in data) {
      if (key.includes('contact')) {
        temp[key] = data[key];
      }
    }
    //set contact object for post request
    const contact = {
      email: temp.contactEmail,
      phone: temp.contactPhone,
      name: temp.contactName,
      contactMethod: temp.contactMethod
    };
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(contact)
      });
      if (!response.ok) throw new Error('Failed to save client');
      const responseData = await response.json();
      setResponse(responseData);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return { saveContact, updateContact, isLoading, error, response };
};
export default useSaveContact;
