import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const useIntakePolicies = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [policies, setPolicies] = useState([]);

  const { getAccessTokenSilently } = useAuth0();

  const fetchIntakePolicies = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/clients/intake-policies`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      if (!response.ok) {
        setError('Network response was not ok');
      }
      const data = await response.json();
      setPolicies(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchIntakePolicies, isLoading, error, policies };
};
export default useIntakePolicies;
