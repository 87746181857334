import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ClientModal from './ClientModal';

const IntakeHeader = () => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const showModal = val => {
    setShow(val);
  };

  const handleClose = () => {
    navigate('/clients');
  };

  return (
    <>
      <ClientModal show={show} handleClose={handleClose} setShow={setShow} />
      <Card className="bg-tc-navy mb-3">
        <Card.Body>
          <Row className="flex-between-center">
            <Col md>
              <h5 className="mb-2 mb-md-0 text-white">
                Business & Security Profile
              </h5>
            </Col>
            <Col xs="auto">
              <span onClick={showModal}>
                <FontAwesomeIcon
                  icon={['fas', 'times-circle']}
                  size="lg"
                  className="text-white"
                  style={{ cursor: 'pointer' }}
                />
              </span>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default IntakeHeader;
