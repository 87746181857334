import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Form,
  Col,
  InputGroup,
  Card,
  Accordion,
  Table,
  Badge
} from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import useIntakeDocuments from 'hooks/useIntakeDocuments';
import Spinner from 'react-bootstrap/Spinner';
import CheckWithIndeterminate from 'components/common/FormCheckWithIndeterminate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';

import '../../../assets/scss/theme/page-styles/_clientIntake.scss';

// Filter to only allow unique values in an array
function arrayUnique(value, index, array) {
  return array.indexOf(value) === index;
}

const DocumentsForm = ({ control, intake, setValue }) => {
  const [searchString, setSearchString] = useState('');
  const { fetchIntakeDocuments, isLoading, documents } = useIntakeDocuments();

  useEffect(() => {
    fetchIntakeDocuments().then(() => {
      if (intake) {
        setValue('documents', intake.documents || []);
      }
    });
  }, [intake, setValue]);

  const filteredDocuments = useMemo(
    () =>
      Object.groupBy(
        documents?.filter(doc => {
          if (searchString) {
            return doc.name.toLowerCase().includes(searchString.toLowerCase());
          }
          return true;
        }) ?? [],
        ({ category }) => category
      ),
    [documents, searchString]
  );

  const categories = useMemo(
    () => Object.keys(filteredDocuments),
    [filteredDocuments]
  );

  return (
    <>
      <div className="d-flex align-items-center mb-2">
        <FontAwesomeIcon
          style={{ color: '#2C7BE5' }}
          icon={faFile}
          size="2x"
          className="me-3 mb-3"
        />
        <div>
          <h4 className="mb-0">Essential Documents</h4>
          <p className="subheader">
            Select all documents your organization maintains
          </p>
        </div>
      </div>

      {isLoading && (
        <Spinner animation="border" size="md" role="Status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
      <Row>
        <Col sm={6} md={5} lg={4}>
          <InputGroup className="mb-3">
            <Form.Control
              type="text"
              placeholder="Search documents"
              aria-label="Search documents"
              value={searchString}
              onChange={e => setSearchString(e.target.value)}
            />
          </InputGroup>
        </Col>
      </Row>
      <Card>
        <Card.Body>
          <Controller
            name="documents"
            control={control}
            defaultValue={intake?.documents || []}
            render={({ field }) => {
              return (
                <Accordion defaultActiveKey={categories} alwaysOpen>
                  <Accordion.Item
                    key="total"
                    eventKey="total"
                    style={{
                      '--falcon-accordion-btn-icon-width': '0px',
                      '--falcon-accordion-btn-icon-height': '0px',
                      '--falcon-accordion-btn-icon': 'none'
                    }}
                  >
                    <Accordion.Header>
                      <span className='dropdown-title'>Total{' '}</span>
                      <Badge
                        pill
                        bg={field.value.length > 0 ? 'primary' : 'secondary'}
                        style={{ marginLeft: '1rem' }}
                      >
                        {field.value.length} selected
                      </Badge>
                    </Accordion.Header>
                  </Accordion.Item>
                  {categories.map(cat => {
                    const selectedDocsInCategory = field.value.filter(doc =>
                      filteredDocuments[cat].map(doc => doc.id).includes(doc)
                    );
                    return (
                      <Accordion.Item key={cat} eventKey={cat}>
                        <Accordion.Header>
                          <span className='dropdown-title'>{cat}{' '}</span>
                          <Badge
                            pill
                            bg={
                              selectedDocsInCategory.length > 0
                                ? 'primary'
                                : 'secondary'
                            }
                            style={{ marginLeft: '1rem' }}
                          >
                            {selectedDocsInCategory.length} selected
                          </Badge>
                        </Accordion.Header>
                        <Accordion.Body>
                          <Table responsive="sm" style={{ fontSize: '13px' }}>
                            <thead>
                              <tr>
                                <th>
                                  <CheckWithIndeterminate
                                    checked={
                                      Array.isArray(field.value) &&
                                      selectedDocsInCategory.length ===
                                        filteredDocuments[cat].length
                                    }
                                    indeterminate={
                                      Array.isArray(field.value) &&
                                      selectedDocsInCategory.length > 0 &&
                                      selectedDocsInCategory.length <
                                        filteredDocuments[cat].length
                                    }
                                    onChange={e => {
                                      if (e.target.checked) {
                                        field.onChange(
                                          [
                                            ...field.value,
                                            ...filteredDocuments[cat].map(
                                              doc => doc.id
                                            )
                                          ].filter(arrayUnique)
                                        );
                                      } else {
                                        field.onChange(
                                          field.value.filter(
                                            id =>
                                              !filteredDocuments[cat]
                                                .map(doc => doc.id)
                                                .includes(id)
                                          )
                                        );
                                      }
                                    }}
                                  />
                                </th>
                                <th>Document Name</th>
                                <th>Purpose</th>
                                <th>Relevant Standards</th>
                              </tr>
                            </thead>
                            <tbody>
                              {filteredDocuments[cat].map(doc => (
                                <tr key={doc.id}>
                                  <td>
                                    <Form.Check
                                      type="checkbox"
                                      id={`doc-${doc.id}`}
                                      value={doc.id}
                                      checked={field.value.includes(doc.id)}
                                      onChange={e => {
                                        const { checked } = e.target;
                                        if (checked) {
                                          field.onChange(
                                            [...field.value, doc.id].filter(
                                              arrayUnique
                                            )
                                          );
                                        } else {
                                          field.onChange(
                                            field.value.filter(
                                              id => id !== doc.id
                                            )
                                          );
                                        }
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Form.Label
                                      htmlFor={`doc-${doc.id}`}
                                      style={{ color: 'var(--falcon-primary)' }}
                                    >
                                      {doc.name}
                                    </Form.Label>
                                  </td>
                                  <td>{doc.purpose}</td>
                                  <td>{doc.standards}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              );
            }}
          />
        </Card.Body>
      </Card>
    </>
  );
};

DocumentsForm.propTypes = {
  control: PropTypes.object,
  intake: PropTypes.object,
  setValue: PropTypes.func.isRequired
};

export default DocumentsForm;
