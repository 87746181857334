import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
import { Row, Col, Accordion, Card, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFolderOpen,
  faCalculator,
  faChevronDown
} from '@fortawesome/free-solid-svg-icons';
import { recordsRanges } from 'data/simulators/impactSimulatorData';

import '../../../assets/scss/theme/page-styles/_clientIntake.scss';

const recordTypesFields = [
  'piiNamesAddresses',
  'piiSSNs',
  'piiBiometricData',
  'piiBirthDates',
  'phiMedicalRecords',
  'phiTreatmentInfo',
  'phiInsuranceInfo',
  'phiDrugInfo',
  'pciCardNumbers',
  'pciTransactionHistories',
  'pciCardholderData',
  'fiBankingDetails',
  'fiTaxRecords',
  'fiPayrollInfo',
  'fiInvestmentData',
  'cbiTradeSecrets',
  'cbiFinancials',
  'cbiAuditDocs',
  'ipPatents',
  'ipDesignSpecs',
  'ipAlgorithms',
  'acUsernamesPasswords',
  'acMFATokens',
  'acAccessLogs',
  'erContractsReviews',
  'erBackgroundChecks',
  'erBenefitsPension',
  'ciPurchaseHistories',
  'ciContractsPricing',
  'ciComplaintsDisputes',
  'regGDPRRecords',
  'regHIPAARecords',
  'regCMMCRecords',
  'regSOXRecords',
  'riskIncidentRecords',
  'riskAssessmentsReports',
  'riskRecoveryPlans',
  'commInternalRecords',
  'commExternalRecords',
  'commVoiceVideo',
  'jusArrestRecords',
  'jusIncarcerationRecords',
  'jusPoliceReports',
  'jusProbationRecords'
];

const RecordsForm = ({ register, errors, intake, setValue }) => {
  useEffect(() => {
    if (intake) {
      setValue('numberEmployees', intake.numberEmployees || '');
      setValue('numberCustomers', intake.numberCustomers || '');
      setValue('numberSuppliers', intake.numberSuppliers || '');
      setValue('recordRetention', intake.recordRetention || '');
      setValue('recordsEstimate', intake.recordsEstimate || '');
      recordTypesFields.forEach(field => {
        setValue(field, intake[field] || false);
      });
    }
  }, [intake, setValue]);

  return (
    <>
      <h4>Sensitive Data Overview</h4>
      <Row className="g-4 mb-3">
        <p className="subheader">
          Please provide details on the types of records your organization
          stores. This will help us understand your data management practices
          better.
        </p>
      </Row>
      <Row className="g-4 mb-2">
        <Col md={3}>
          <WizardInput
            type="number"
            label="Number of Employees:"
            sublabel="How many employees does your organization have?"
            name="numberEmployees"
            errors={errors}
            formGroupProps={{
              className: 'mb-3 sublabel-wrap md-num-lines-3'
            }}
            formControlProps={{
              ...register('numberEmployees')
            }}
          />
        </Col>
        <Col md={3}>
          <WizardInput
            type="number"
            label="Number of Customers:"
            sublabel="How many customers do you have?"
            name="numberCustomers"
            errors={errors}
            formGroupProps={{
              className: 'mb-3 sublabel-wrap md-num-lines-3'
            }}
            formControlProps={{
              ...register('numberCustomers')
            }}
          />
        </Col>
        <Col md={3}>
          <WizardInput
            type="number"
            label="Number of Suppliers:"
            sublabel="How many suppliers (up and down the chain) does your organization work with?"
            name="numberSuppliers"
            errors={errors}
            formGroupProps={{
              className: 'mb-3 sublabel-wrap md-num-lines-3'
            }}
            formControlProps={{
              ...register('numberSuppliers')
            }}
          />
        </Col>
        <Col md={3}>
          <WizardInput
            type="text"
            label="Record Retention Duration:"
            sublabel="How long do you retain records (in years)?"
            name="recordRetention"
            errors={errors}
            formGroupProps={{
              className: 'mb-3 sublabel-wrap md-num-lines-3'
            }}
            formControlProps={{
              ...register('recordRetention')
            }}
          />
        </Col>
      </Row>
      <Card className="mb-3">
        <Card.Body>
          <Row className="g-4 mb-2 text-center">
            <Col>
              <WizardInput
                type="number"
                label="Estimated Number of Sensitive Data Records"
                placeholder="Enter # of records or use the Records Estimator below"
                name="recordsEstimate"
                errors={errors}
                formGroupProps={{
                  className: 'records-estimate'
                }}
                formControlProps={{
                  ...register('recordsEstimate')
                }}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <SimpleAccordion
        closedHeader={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              gap: '1em'
            }}
          >
            <FontAwesomeIcon icon={faCalculator} style={{ color: '#2479E9' }} />
            <p>Records Estimator</p>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        }
        openHeader={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <FontAwesomeIcon
              icon={faFolderOpen}
              className="me-2"
              style={{ color: '#2479E9', fontSize: '16px' }}
            />
            <div>
              <h5>Record Types</h5>
              <Row className="g-4">
                <p className="subheader">
                  For each record type listed below, please indicate if your
                  organization stores this type of data by checking the relevant
                  boxes.
                </p>
              </Row>
            </div>
          </div>
        }
      >
        <Accordion>
          <Accordion.Item eventKey="pii">
            <Accordion.Header>
              <p className="header dropdownColor">Personal Identifiable Information (PII)</p>
              <p className="subheader dropdownColor">
                Examples: names, addresses, phone numbers, email addresses,
                Social Security Numbers, biometric data.
              </p>
            </Accordion.Header>
            <Accordion.Body>
              <WizardInput
                type="checkbox"
                stacked
                label="Names and addresses"
                name="piiNamesAddresses"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('piiNamesAddresses')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="Social Security Numbers (SSNs) or other IDs"
                name="piiSSNs"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('piiSSNs')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="Biometeric data"
                name="piiBiometricData"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('piiBiometricData')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="Birth dates"
                name="piiBirthDates"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('piiBirthDates')
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="phi">
            <Accordion.Header>
              <p className="header dropdownColor">Protected Health Information (PHI)</p>
              <p className="subheader dropdownColor">
                Examples: names, addresses, phone numbers, email addresses,
                Social Security Numbers, biometric data.
              </p>
            </Accordion.Header>
            <Accordion.Body>
              <WizardInput
                type="checkbox"
                stacked
                label="Medical records or health-related information"
                name="phiMedicalRecords"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('phiMedicalRecords')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="Patient treatment or diagnosis info"
                name="phiTreatmentInfo"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('phiTreatmentInfo')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="Health insurance information"
                name="phiInsuranceInfo"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('phiInsuranceInfo')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="Prescription drug information"
                name="phiDrugInfo"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('phiDrugInfo')
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="pci">
            <Accordion.Header>
              <p className="header dropdownColor">Payment Card Information (PCI)</p>
              <p className="subheader dropdownColor">
                Examples: credit/debit card numbers, cardholder names,
                expiration dates, security codes.
              </p>
            </Accordion.Header>
            <Accordion.Body>
              <WizardInput
                type="checkbox"
                stacked
                label="Credit/debit card numbers"
                name="pciCardNumbers"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('pciCardNumbers')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="Payment transaction histories"
                name="pciTransactionHistories"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('pciTransactionHistories')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="Cardholder data including security codes"
                name="pciCardholderData"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('pciCardholderData')
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="fi">
            <Accordion.Header>
              <p className="header dropdownColor">Financial Information</p>
              <p className="subheader dropdownColor">
                Examples: banking details, tax records, payroll information,
                investment portfolios.
              </p>
            </Accordion.Header>
            <Accordion.Body>
              <WizardInput
                type="checkbox"
                stacked
                label="Banking details"
                name="fiBankingDetails"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('fiBankingDetails')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="Tax records"
                name="fiTaxRecords"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('fiTaxRecords')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="Salary or payroll information"
                name="fiPayrollInfo"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('fiPayrollInfo')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="Investment or portfolio data"
                name="fiInvestmentData"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('fiInvestmentData')
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="cbi">
            <Accordion.Header>
              <p className="header dropdownColor">Confidential Business Information</p>
              <p className="subheader dropdownColor">
                Examples: trade secrets, business plans, financial reports,
                internal audit documentation.
              </p>
            </Accordion.Header>
            <Accordion.Body>
              <WizardInput
                type="checkbox"
                stacked
                label="Trade secrets or proprietary formulas"
                name="cbiTradeSecrets"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('cbiTradeSecrets')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="Non-public company financials"
                name="cbiFinancials"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('cbiFinancials')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="Internal audit or compliance documentation"
                name="cbiAuditDocs"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('cbiAuditDocs')
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="ip">
            <Accordion.Header>
              <p className="header dropdownColor">Intellectual Property</p>
              <p className="subheader dropdownColor">
                Examples: patents, trademarks, copyrights, design
                specifications, proprietary technologies.
              </p>
            </Accordion.Header>
            <Accordion.Body>
              <WizardInput
                type="checkbox"
                stacked
                label="Patents, trademarks, copyrights"
                name="ipPatents"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('ipPatents')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="Design specifications or software code"
                name="ipDesignSpecs"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('ipDesignSpecs')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="Proprietary algorithms or technologies"
                name="ipAlgorithms"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('ipAlgorithms')
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="ac">
            <Accordion.Header>
              <p className="header dropdownColor">User Authentication and Access Control</p>
              <p className="subheader dropdownColor">
                Examples: usernames, passwords, MFA tokens, access logs,
                security event records.
              </p>
            </Accordion.Header>
            <Accordion.Body>
              <WizardInput
                type="checkbox"
                stacked
                label="Usernames and passwords"
                name="acUsernamesPasswords"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('acUsernamesPasswords')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="MFA tokens or data"
                name="acMFATokens"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('acMFATokens')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="User access logs or security event logs"
                name="acAccessLogs"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('acAccessLogs')
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="er">
            <Accordion.Header>
              <p className="header dropdownColor">Employee Records</p>
              <p className="subheader dropdownColor">
                Examples: employment contracts, performance reviews, background
                checks, security clearances.
              </p>
            </Accordion.Header>
            <Accordion.Body>
              <WizardInput
                type="checkbox"
                stacked
                label="Employment contracts or performance reviews"
                name="erContractsReviews"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('erContractsReviews')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="Background checks or security clearances"
                name="erBackgroundChecks"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('erBackgroundChecks')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="Benefits or pension-related information"
                name="erBenefitsPension"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('erBenefitsPension')
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="ci">
            <Accordion.Header>
              <p className="header dropdownColor">Customer and Vendor Information</p>
              <p className="subheader dropdownColor">
                Examples: purchase histories, contact information, contracts,
                pricing agreements, SLAs.
              </p>
            </Accordion.Header>
            <Accordion.Body>
              <WizardInput
                type="checkbox"
                stacked
                label="Customer purchase histories"
                name="ciPurchaseHistories"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('ciPurchaseHistories')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="Vendor contracts or pricing agreements"
                name="ciContractsPricing"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('ciContractsPricing')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="Records of customer complaints or disputes"
                name="ciComplaintsDisputes"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('ciComplaintsDisputes')
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="reg">
            <Accordion.Header>
              <p className="header dropdownColor">Compliance and Regulatory Records</p>
              <p className="subheader dropdownColor">
                Examples: GDPR, HIPAA, CMMC, SOX compliance documentation.
              </p>
            </Accordion.Header>
            <Accordion.Body>
              <WizardInput
                type="checkbox"
                stacked
                label="GDPR or privacy regulation records"
                name="regGDPRRecords"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('regGDPRRecords')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="HIPAA or health-related regulation records"
                name="regHIPAARecords"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('regHIPAARecords')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="CMMC-related documentation"
                name="regCMMCRecords"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('regCMMCRecords')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="SOX or financial compliance records"
                name="regSOXRecords"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('regSOXRecords')
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="risk">
            <Accordion.Header>
              <p className="header dropdownColor">Incident and Risk Management</p>
              <p className="subheader dropdownColor">
                Examples: risk assessments, vulnerability reports, incident
                logs, disaster recovery plans.
              </p>
            </Accordion.Header>
            <Accordion.Body>
              <WizardInput
                type="checkbox"
                stacked
                label="Records of past security incidents"
                name="riskIncidentRecords"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('riskIncidentRecords')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="Risk assessments or vulnerability reports"
                name="riskAssessmentsReports"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('riskAssessmentsReports')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="Disaster recovery or business continuity plans"
                name="riskRecoveryPlans"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('riskRecoveryPlans')
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="comm">
            <Accordion.Header>
              <p className="header dropdownColor">Communications Data</p>
              <p className="subheader dropdownColor">
                Examples: emails, chat logs, voice calls, video conferences.
              </p>
            </Accordion.Header>
            <Accordion.Body>
              <WizardInput
                type="checkbox"
                stacked
                label="Internal communication records (emails, chat logs)"
                name="commInternalRecords"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('commInternalRecords')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="External communications with clients or partners"
                name="commExternalRecords"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('commExternalRecords')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="Voice calls or video conferences"
                name="commVoiceVideo"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('commVoiceVideo')
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="jus">
            <Accordion.Header>
              <p className="header dropdownColor">Criminal Justice</p>
              <p className="subheader dropdownColor">
                Examples: criminal history records, incarcerations, incident
                reports, probation records
              </p>
            </Accordion.Header>
            <Accordion.Body>
              <WizardInput
                type="checkbox"
                stacked
                label="Arrest records, charges, convictions, court verdicts, sentencing"
                name="jusArrestRecords"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('jusArrestRecords')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="Incarceration booking details, prison/jail records, parole/probation information, release data"
                name="jusIncarcerationRecords"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('jusIncarcerationRecords')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="Police reports, crime scene evidence, surveillance footage, witness statements"
                name="jusPoliceReports"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('jusPoliceReports')
                }}
              />
              <WizardInput
                type="checkbox"
                stacked
                label="Probation terms, compliance reports, parole violations, rehabilitation progress"
                name="jusProbationRecords"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('jusProbationRecords')
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </SimpleAccordion>
    </>
  );
};

RecordsForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  intake: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired
};

export default RecordsForm;

const SimpleAccordion = ({ closedHeader, openHeader, children }) => {
  const [open, setOpen] = useState(false);
  return (
    <Card className="mb-3">
      <Card.Header onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
        {open ? openHeader : closedHeader}
      </Card.Header>
      {open && <Card.Body>{children}</Card.Body>}
    </Card>
  );
};

SimpleAccordion.propTypes = {
  closedHeader: PropTypes.node,
  openHeader: PropTypes.node,
  children: PropTypes.node
};

export const EstimateDataVolumeButton = ({ getValues, setValue }) => {
  const [totalRecordsDialogOpen, setTotalRecordsDialogOpen] = useState(false);
  const [recordsEstimate, setRecordsEstimate] = useState(0);

  const estimateDataVolume = useCallback(() => {
    const recordTypes = getValues();
    const totalRecordsTypes = recordTypesFields.filter(
      field => recordTypes[field]
    ).length;
    const numberEmployees = parseInt(recordTypes.numberEmployees) || 0;
    const numberCustomers = parseInt(recordTypes.numberCustomers) || 0;
    const numberSuppliers = parseInt(recordTypes.numberSuppliers) || 0;
    const newRecordsEstimate =
      totalRecordsTypes * (numberEmployees + numberCustomers + numberSuppliers);
    setRecordsEstimate(newRecordsEstimate);
    setTotalRecordsDialogOpen(true);
  }, [getValues]);

  const setEstimative = () => {
    setValue('recordsEstimate', recordsEstimate);
    setTotalRecordsDialogOpen(false);
  };

  const getCompanySizeFromRecords = () => {
    const range =
      recordsRanges.find(
        range => recordsEstimate >= range.min && recordsEstimate <= range.max
      ) ?? recordsRanges[0];
    return { dataVolumeRange: range.range, dataVolumeLabel: range.label };
  };

  const { dataVolumeLabel, dataVolumeRange } = getCompanySizeFromRecords();

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          marginTop: '1em',
          marginBottom: '1em'
        }}
      >
        <Button
          variant="outline-danger"
          type="button"
          onClick={estimateDataVolume}
        >
          <FontAwesomeIcon icon={faCalculator} className="me-2" />
          Estimate Data Volume
        </Button>
      </div>
      <Modal
        show={totalRecordsDialogOpen}
        onHide={() => setTotalRecordsDialogOpen(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Estimated Data Volume</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <FontAwesomeIcon
              icon={faCalculator}
              size="10x"
              style={{
                color: 'var(--falcon-primary)',
                transform: 'rotate(-45deg)',
                margin: '3rem'
              }}
            />
            <h5 className="mt-2">
              Company Size: <strong>{dataVolumeLabel}</strong>
            </h5>
            <p style={{ textAlign: 'center' }}>
              Estimated Sensitive Data:{' '}
              {Intl.NumberFormat('en-US').format(recordsEstimate)} records
              <br />
              Estimated Data Range: {dataVolumeRange} records held
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => setTotalRecordsDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button type="button" variant="primary" onClick={setEstimative}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

EstimateDataVolumeButton.propTypes = {
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired
};
