import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { PieChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { useAppContext } from 'providers/AppProvider';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
  LegendComponent
]);

const getOptions = (getThemeColor, data) => ({
  color: [
    getThemeColor('info'),
    getThemeColor('primary'),
    getThemeColor('light-blue'),
    getThemeColor('warning')
  ],
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getThemeColor('gray-100'),
    borderColor: getThemeColor('gray-300'),
    textStyle: { color: getThemeColor('gray-1100') },
    borderWidth: 1,
    transitionDuration: 0,
    formatter: function (params) {
      return `<strong>${params.data.name}:</strong> ${
        params.percent
      }%<br><strong>Total Cost</strong>:${
        params.value > 1000000
          ? `$${(params.value / 1000000).toFixed(2)}M`
          : `$${(params.value / 1000).toFixed(2)}K`
      }`;
    }
  },
  legend: { show: false },
  series: [
    {
      name: 'Total',
      type: 'pie',
      radius: ['100%', '67%'], // Inner and outer radius
      avoidLabelOverlap: false,
      itemStyle: {
        borderWidth: 2,
        borderColor: getThemeColor('gray-100')
      },
      label: {
        show: false
      },
      emphasis: {
        scale: false
      },
      labelLine: { show: false },
      data: data
    }
  ]
});

const ConfidentialityBreachChart = ({ data }) => {
  const chartRef = useRef(null);
  const { getThemeColor } = useAppContext();
  let total = 0;
  for (const item of data) {
    total += item.value;
  }

  return (
    <div className="position-relative py-2">
      <ReactEChartsCore
        ref={chartRef}
        echarts={echarts}
        option={getOptions(getThemeColor, data)}
        style={{ height: '14.9rem' }}
      />
      <div className="position-absolute top-50 start-50 translate-middle text-center">
        <p className="fs-10 mb-0 text-400 font-sans-serif fw-medium">Likely Breach Cost</p>
        <p className="fs-6 mb-0 font-sans-serif fw-medium mt-n2">
          ${(total / 1000000).toFixed(2)}M
        </p>
      </div>
    </div>
  );
};

ConfidentialityBreachChart.propTypes = {
  data: PropTypes.array
};

export default ConfidentialityBreachChart;
