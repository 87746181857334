const securityPostureCalculator = (intakeForm) => {

    const getCyberPolicyScore = () => {
        var score = 0;

        if (!intakeForm?.policies) return score;

        var numOfPolicies = intakeForm.policies.length;
        score = 0.25 * numOfPolicies;
        return (score > 5 ? 5 : score);
    }

    const findTrainingValue = (formValue) => {
        if (formValue === "Multiple times per year") {
            return 1.6;
        } else if (formValue === "Once per year") {
            return 0.8;
        } else {
            return 0;
        }
    }

    const getTrainingScoresValue = () => {
        var score = 0;

        if (intakeForm === null) {
            return 0;
        } else {
            score += findTrainingValue(intakeForm.incidentResponse);
            score += findTrainingValue(intakeForm.disasterRecovery);
            score += findTrainingValue(intakeForm.tabletop);
        }

        return (score > 4.9 ? 5 : score);
    }

    const getLeadershipScore = () => {
        var score = 0;

        if (intakeForm === null) {
            return 0;
        } else if (intakeForm.securityTeam === "We have a dedicated security team with a CISO") {
            score += 5;
        } else if (intakeForm.securityTeam === "We have a dedicated security team that reports to the IT department") {
            score += 2.5;
        } else {
            score += 0;
        }

        if (intakeForm.disconnect) {
            score -= 1;
        }
        if (intakeForm.fear) {
            score -= 1;
        }
        if (intakeForm.hopeless) {
            score -= 1;
        }
        if (intakeForm.nonInclusion) {
            score -= 1;
        }
        if (intakeForm.businessFocus) {
            score -= 1;
        }

        if (score < 0) {
            score = 0;
        }

        return score;
    }

    const getComplianceScore = (score1, score2, score3) => {
        var score = ((score1 + score2 + score3) / 3);
        
        return score;
    }

    const securityScoreValue = (targetVal) => {
        if (targetVal == 2) return 0.45;
        if (targetVal == 1) return 0.225;
        if (targetVal == 0) return 0;
    }

    const getSecurityControlsScore = () => {
        var score = 0;

        if (intakeForm?.iam) {
            score += securityScoreValue(intakeForm.iam);
        }

        if (intakeForm?.cm) {
            score += securityScoreValue(intakeForm.cm);
        }

        if (intakeForm?.uat) {
            score += securityScoreValue(intakeForm.uat);
        }

        if (intakeForm?.secureArch) {
            score += securityScoreValue(intakeForm.secureArch);
        }

        if (intakeForm?.edr) {
            score += securityScoreValue(intakeForm.edr);
        }

        if (intakeForm?.grc) {
            score += securityScoreValue(intakeForm.grc);
        }

        if (intakeForm?.nip) {
            score += securityScoreValue(intakeForm.nip);
        }

        if (intakeForm?.wf) {
            score += securityScoreValue(intakeForm.wf);
        }

        if (intakeForm?.irm) {
            score += securityScoreValue(intakeForm.irm);
        }

        if (intakeForm?.cm) {
            score += securityScoreValue(intakeForm.contentManagement);
        }

        if (intakeForm?.ti) {
            score += securityScoreValue(intakeForm.ti);
        }

        return (score > 4.9 ? 5 : score);
    }

    const getRiskMitigationDocumentationScore = () => {
        var score = 0;

        if (!intakeForm?.documents) return score;

        var numOfDocuments = intakeForm.documents.length;
        score = 0.333 * numOfDocuments;

        return (score > 4.9) ? 5.0 : score;
    }

    const cyberPolicyScore = getCyberPolicyScore();
    const trainingScoresValue = getTrainingScoresValue();
    const leadershipScore = getLeadershipScore();
    const securityControlsScore = getSecurityControlsScore();
    const riskMitigationDocumentationScore = getRiskMitigationDocumentationScore();
    const complianceScore = getComplianceScore(cyberPolicyScore, riskMitigationDocumentationScore, securityControlsScore);

    const securityPostureValues = {
        cyberPolicy: cyberPolicyScore.toPrecision(3),
        trainingScores: trainingScoresValue.toPrecision(3),
        leadership: leadershipScore.toPrecision(3),
        compliance: complianceScore.toPrecision(3),
        securityControls: securityControlsScore.toPrecision(3),
        riskMitigationDocumentation: riskMitigationDocumentationScore.toPrecision(3)
    };

    return [
        securityPostureValues.cyberPolicy,
        securityPostureValues.trainingScores,
        securityPostureValues.leadership,
        securityPostureValues.compliance,
        securityPostureValues.securityControls,
        securityPostureValues.riskMitigationDocumentation
    ];
};

export default securityPostureCalculator;