import React, { useEffect, useState, useMemo } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { ArrowRight } from 'react-bootstrap-icons';
import Greetings from './greetings/Greetings';
import ConfidentialityBreach from './confidentiality-breach/ConfidentialityBreach';
import SimStat from './SimStat';
import ImpactCostGraph from './ImpactCostGraph';
import ImpactSimulatorForm from './ImpactSimulatorForm';
import {
  generateImpactStats,
  generateBreachCostData,
  generateBreachGraphData
} from 'data/simulators/impactSimulatorData';
import usePostImpact from 'hooks/usePostImpact';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import WaitingCard from 'helpers/WaitingCard';

const ImpactSimulator = () => {
  const [formData, setFormData] = useState({});
  const { postImpact } = usePostImpact();
  const [impactResponse, setImpactResponse] = useState(null);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [loadStatus, setLoadStatus] = useState('default');

  useEffect(() => {
    if (Object.keys(formData).length === 0) return;

    const fetchImpact = async () => {
      setIsLoading(true);
      try {
        // await toast.promise(postImpact(formData, setImpactResponse), {
        //   pending: 'Calculating impact...',
        //   success: 'Impact calculated!',
        //   error: 'An error occurred. Please try again later.'
        // });
        const response = await postImpact(formData, setImpactResponse);

        if (response === true) {
          setLoadStatus('success');
          setTimeout(() => {
            setLoadStatus('default');
            setIsLoading(false);
          }, 2000);

        } else {
          setLoadStatus('error');
          setTimeout(() => {
            setLoadStatus('default');
            setIsLoading(false);
          }, 4000);
        }

      } catch (error) {
        console.error('Error fetching impact:', error);
        setLoadStatus('error');
        setTimeout(() => {
          setLoadStatus('default');
          setIsLoading(false);
        }, 4000);
      }
    };
    fetchImpact();
  }, [formData]);

  const breachGraphData = useMemo(
    () => generateBreachGraphData(impactResponse),
    [impactResponse]
  );
  const impactStats = useMemo(
    () => generateImpactStats(impactResponse),
    [impactResponse]
  );
  const breachCostData = useMemo(
    () => generateBreachCostData(impactResponse),
    [impactResponse]
  );

  const goToLikelihood = () => {
    navigate('/simulator/likelihood', {
      state: {
        simulationId: impactResponse.simulationId,
        isProspect: formData.prospect,
        prospectName: formData.prospectName,
        mspClientId: formData.mspClientId
      }
    });
  };

  return (
    <>
      <ToastContainer />
      {isLoading && <WaitingCard type={'impact'} status={loadStatus}/>}
      <Greetings />
      <ImpactSimulatorForm setFormData={setFormData} goToLikelihoodPage={goToLikelihood} impactResponse={impactResponse}/>
      <Row className="g-3 mb-3">
        <Col xxl={4} lg={4}>
          <ConfidentialityBreach data={breachGraphData} />
        </Col>
        <Col xxl={8} lg={8}>
          {/* <Row className="g-3">
            <SimStat data={impactStats} />
          </Row> */}
          <Row className="g-3 mb-3">
            <ImpactCostGraph data={breachCostData} />
          </Row>
          <Row className="g-3">{/* <ImpactMSPTalkingPoints /> */}</Row>
        </Col>
      </Row>
      {/* <Row className="g-3 mb-1 flex-row-reverse">
        <Col sm={3}>
          <Button
            type="button"
            variant="warning"
            className="w-100"
            disabled={impactResponse === null}
            onClick={goToLikelihood}
          >
            Evaluate Insurability <ArrowRight />
          </Button>
        </Col>
      </Row> */}
    </>
  );
};

export default ImpactSimulator;
