import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const useIntake = (clientId = null) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  if (!clientId) {
    clientId = localStorage.getItem('clientId');
  }
  const { getAccessTokenSilently } = useAuth0();

  const fetchIntake = async setIntake => {
    setIsLoading(true);
    setError(null);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/clients/${clientId}/intake`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setResponse(data);
      //setIntake(data);
      setIntake(prevIntake => ({
        ...prevIntake,
        ...data
      }));
      //save to local storage because inital 'intake' is {} and
      //we need to the data to be available in the wizard
      localStorage.setItem('intake', JSON.stringify(data));
    } catch (error) {
      console.error('Error fetching Intake Form:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchIntakeForClient = async newClientId => {
    setIsLoading(true);
    setError(null);

    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/clients/${newClientId}/intake`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      localStorage.setItem('intake', JSON.stringify(data));
      return data;
    } catch (error) {
      console.error('Error fetching Intake Form:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchIntake, fetchIntakeForClient, isLoading, error, response };
};
export default useIntake;
