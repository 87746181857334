import useGetOneClient from 'hooks/useGetOneClient';
import useSaveContact from 'hooks/useSaveContact';
import PropTypes from 'prop-types';
import { useClientWizardContext } from 'providers/ClientWizardProvider';
import React, { useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import paths from 'routes/paths';
import GeneralForm from '../ClientIntakeWizard/GeneralForm';

const WizardLayout = ({ validation }) => {
  const navigate = useNavigate();
  const { client, setClient } = useClientWizardContext();
  const { clientId } = useParams();
  const { updateContact, saveContact } = useSaveContact();
  const { getClient, updateClient } = useGetOneClient();
  const [validationError, setValidationError] = useState('');

  useEffect(() => {
    const getClientDetails = async clientId => {
      try {
        const clientDetail = await getClient(clientId);

        setClient(clientDetail);
      } catch (error) {
        throw new Error(error.message);
      }
    };

    getClientDetails(clientId);
  }, [clientId]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
    control
  } = useForm({
    defaultValues: { ...client }
  });

  const prepareContacts = data => {
    if (!Array.isArray(data.contact) || !data.contact.length) {
      data.contacts = [{}];
    }

    data.contacts[0] = {
      ...data?.contacts[0],
      contactMethod: data.contactMethod,
      name: data.contactName,
      phone: data.contactPhone,
      email: data.contactEmail
    };

    return data;
  };

  const handleExistingClient = async (data, updatedClient) => {
    const { status, error } = await updateClient(
      updatedClient.id,
      updatedClient
    );
    if (status === 200) {
      setValidationError('');

      if (client.contacts[0]?.id) {
        await updateContact(
          data,
          client.contacts[0]?.id ?? '',
          clientId ?? client.id
        );
      } else {
        await saveContact(data, clientId ?? client.id);
      }

      navigate(paths.clients);
    } else {
      handleErrorOrValidationError(error);
    }
  };

  const handleStepOne = async data => {
    data = prepareContacts(data);
    const updatedClient = { ...client, ...data };

    setClient(updatedClient);

    await handleExistingClient(data, updatedClient);
  };

  const handleErrorOrValidationError = error => {
    if (typeof error === 'object' && error.error) {
      handleError(error);
    } else {
      setValidationError(error);
    }
    console.error('API Error:', error);
  };

  const onSubmitData = async data => {
    await handleStepOne(data);
    navigate(paths.clients);
  };

  const handleError = error => {
    if (error.error === 'DuplicateClient') {
      if (error.sameName) {
        setError('name', {
          type: 'custom',
          message: 'Client with same name already exist'
        });
      }
      if (error.sameEmail) {
        setError('email', {
          type: 'custom',
          message: 'Client with same e-mail already exist'
        });
      }
      if (error.samePhone) {
        setError('phone', {
          type: 'custom',
          message: 'Client with same phone already exist'
        });
      }
    } else {
      setValidationError(error.message);
    }
  };

  const onError = () => {
    if (!validation) {
      clearErrors();
    }
  };

  return (
    <div className="client-intake">
      <Card
        as={Form}
        noValidate
        onSubmit={handleSubmit(onSubmitData, onError)}
        className="theme-wizard mb-5 p-0"
        style={{ maxHeight: 'none' }}
      >
        <Card.Body className="fw-normal px-md-6 py-4 intake-step">
          <GeneralForm
            register={register}
            errors={errors}
            setValue={setValue}
            client={client}
            control={control}
          />
          {validationError && <p className="text-danger">{validationError}</p>}
        </Card.Body>
        <Card.Footer className="px-md-6 bg-body-tertiary">
          <div className="gap-3 d-flex justify-content-end">
            <Button
              variant="primary"
              className="ps-2 pe-2 m-1"
              type="submit"
              transform="down-1 shrink-4"
            >
              Submit
            </Button>
          </div>
        </Card.Footer>
      </Card>
    </div>
  );
};

WizardLayout.propTypes = {
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

export default WizardLayout;
