import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const useSimulations = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState(null);
    const [client, setClient] = useState(null);
    const [date, setDate] = useState(null);
    const { getAccessTokenSilently } = useAuth0();

    const listSimulations = async (mspClientId) => {
        setIsLoading(true);
        setError(null);

        try {
            const accessToken = await getAccessTokenSilently();
            const response = await fetch(
                `${process.env.REACT_APP_API_SERVER_URL}/simulations/list/${mspClientId}`,
                {
                    method: 'GET',
                    headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                    }
                }
            );

            if (!response.ok) throw new Error('Failed to get client');

            const responseData = await response.json();
            setResponse(responseData);
            return responseData;

        } catch (error) {
            throw new Error(error.message);
        } finally {
            setIsLoading(false);
        }
    }

    const getNewestSim = async (mspClientId) => {
        setIsLoading(true);
        setError(null);

        if (!mspClientId) {
            setIsLoading(false);
            return null;
        }

        try {
            const accessToken = await getAccessTokenSilently();
            const response = await fetch(
                `${process.env.REACT_APP_API_SERVER_URL}/simulations/newest/${mspClientId}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            );

            if (!response.ok) throw new Error('Failed to get client');

            const responseText = await response.text();
            if (!responseText) {
                setResponse(null);
                return null;
            }

            const responseData = JSON.parse(responseText);
            setResponse(responseData);
            return responseData;

        } catch (error) {
            throw new Error(error.message);
        } finally {
            setIsLoading(false);
        }
    }

    return { listSimulations, getNewestSim, response };
}

export default useSimulations;