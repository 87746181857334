import React from 'react';
import { Row } from 'react-bootstrap';
import Wizard from './EditOrganization/Wizard';

const EditOrganization = () => {
  return (
    <>
      <Row className="g-3">
        <Wizard validation={true} />
      </Row>
    </>
  );
};

export default EditOrganization;
