import 'assets/css/navigation.css';
import paths, { rootPaths } from './paths';
// import paths from './paths';

export const navRoutes = {
  label: 'dashboards',
  labelDisable: true,
  children: [
    {
      name: 'Home',
      active: true,
      icon: 'home',
      to: paths.landing,
      exact: true
    },
    {
      name: 'Financial Risk Simulator',
      icon: 'chart-pie',
      to: paths.impactSimulator,
      active: true
      // children: [
      //   {
      //     name: 'Impact Simulator',
      //     to: paths.impactSimulator,
      //     active: true,
      //     exact: true
      //   },
      //   {
      //     name: 'Likelihood Simulator',
      //     to: paths.likelihoodSimulator,
      //     active: true,
      //     exact: true
      //   }
      // ]
    },
    {
      name: 'Organizations',
      icon: 'users',
      to: paths.clients,
      active: true
      // children: [
      //   {
      //     name: 'Prospects',
      //     to: paths.prospects,
      //     active: true
      //   },
      //   {
      //     name: 'Existing Clients',
      //     to: paths.clients,
      //     active: true
      //   }
      // ]
    },
    {
      name: 'Threat Academy',
      icon: 'graduation-cap',
      //create the reports page based on UI design
      to: paths.academy,
      active: false,
      // children: []
    },
    {
      name: 'Reports',
      icon: 'chart-line',
      //create the reports page based on UI design
      to: paths.reports,
      active: false,
      badge: {
        type: 'info',
        text: 'Coming Soon'
      }
      // children: []
    }
  ]
};

// export const resourcesRoutes = {
//   label: 'resources',
//   labelDisable: true,
//   children: [
//     // {
//     //   name: 'Resources',
//     //   active: true,
//     //   icon: 'book',
//     //   children: []
//     // },
//     // {
//     //   name: 'Support',
//     //   icon: 'question-circle',
//     //   //create the clients page based on UI design
//     //   active: true,
//     //   children: []
//     // },
//     // {
//     //   name: 'Settings',
//     //   icon: 'cog',
//     //   //create the risk analysis page based on UI design
//     //   active: true
//     // }
//   ]
// };
export default [navRoutes];
