import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const useGetOneClient = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [client, setClient] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const getClient = async clientId => {
    setIsLoading(true);
    setError(null);

    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/clients/${clientId}/get`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (!response.ok) throw new Error('Failed to get client');

      const responseData = await response.json();
      setClient(responseData);
      setResponse(responseData);
      return responseData;
    } catch (error) {
      throw new Error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const updateClient = async (clientId, clientUpdates) => {
    setIsLoading(true);
    setError(null);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/clients/${clientId}/update`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify(clientUpdates)
        }
      );

      if (!response.ok) {
        // Parse the error message
        if (response.status === 409) {
          try {
            const errorData = await response.json();
            return { status: response.status, error: errorData };
          } catch (error) {
            console.log(error);
            return { status: response.status, error: await response.text() };
          }
        } else {
          return { status: response.status, error: await response.text() };
        }
      }

      const responseData = await response.json();
      setClient(responseData);
      setResponse(responseData);
      return { status: response.status, data: responseData };
    } catch (error) {
      throw new Error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const upgradeClient = async (clientId) => {
    setIsLoading(true);
    setError(null);

    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/clients/${clientId}/upgrade`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (!response.ok) throw new Error('Failed to get client');

      const responseData = await response.json();
      setClient(responseData);
      setResponse(responseData);
      return responseData;
    } catch (error) {
      throw new Error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    getClient,
    upgradeClient,
    updateClient,
    client,
    isLoading,
    error,
    response
  };
};

export default useGetOneClient;
