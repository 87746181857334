import React from 'react';
import PropTypes from 'prop-types';
import WizardLayout from './WizardLayout';
import ClientWizardProvider from 'providers/ClientWizardProvider';
import './intake.css';

const Wizard = ({ variant, validation, progressBar, operation }) => {
  return (
    <ClientWizardProvider>
      <WizardLayout
        variant={variant}
        validation={validation}
        progressBar={progressBar}
        operation={operation}
      />
    </ClientWizardProvider>
  );
};

Wizard.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool,
  operation: PropTypes.oneOf(['add', 'update'])
};

export default Wizard;
