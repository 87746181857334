import { yupResolver } from '@hookform/resolvers/yup';
import useClients from 'hooks/useClients';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import * as yup from 'yup';
import ControlDescriptionTooltip from './ControlDescriptionTooltip';
import LikelihoodRange from './LikelihoodRange';
import SecControlIcon from './SecControlIcon';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import useReport from 'hooks/useReport';
import useIntake from 'hooks/useIntake';

// Icons
import iam from 'assets/img/security-controls/iam.png';
import uat from 'assets/img/security-controls/uat.png';
import secArch from 'assets/img/security-controls/secArch.png';
import edr from 'assets/img/security-controls/edr.png';
import ti from 'assets/img/security-controls/ti.png';
import nip from 'assets/img/security-controls/nip.png';
import irm from 'assets/img/security-controls/irm.png';
import wf from 'assets/img/security-controls/wf.png';
import content from 'assets/img/security-controls/content.png';
import cm from 'assets/img/security-controls/cm.png';
import grc from 'assets/img/security-controls/grc.png';

// CSS
import './LikelihoodSimulatorForm.css';

const schema = yup
  .object({
    clientType: yup.string().required('This field is required.'),
    prospectName: yup.string().when('clientType', {
      is: 'prospect',
      then: schema => schema.required('This field is required.'),
      otherwise: schema => schema.notRequired()
    }),
    clientName: yup.object().when('clientType', {
      is: 'client',
      then: () =>
        yup.object({
          value: yup.string().required('This field is required.')
        }),
      otherwise: () => yup.object({ value: yup.string().notRequired() })
    }),
    iam: yup.number().default(0),
    configMgmt: yup.number().default(0),
    uat: yup.number().default(0),
    secureArch: yup.number().default(0),
    edr: yup.number().default(0),
    grc: yup.number().default(0),
    nip: yup.number().default(0),
    wf: yup.number().default(0),
    irm: yup.number().default(0),
    contentMgmt: yup.number().default(0),
    ti: yup.number().default(0)
  })
  .required();

const LikelihoodSimulatorForm = ({
  simulationId,
  setSimulationId,
  setFormData,
  hasResult
}) => {
  const clients = useClients();
  const [isProspect, setIsProspect] = useState(false);
  const location = useLocation();
  const hasStartingValues = location.state !== undefined;
  const { downloadReport, isLoading } = useReport();
  const { fetchIntakeForClient } = useIntake();

  const handleRadioChange = event => {
    setIsProspect(event.target.value === 'prospect');
  };

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
    watch
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (location.state) {
      setIsProspect(location.state.isProspect);
      setValue('clientType', location.state.isProspect ? 'prospect' : 'client');
      setValue('prospectName', location.state.prospectName);
      setValue('clientName', {
        value: location.state.mspClientId,
        label: clients[location.state.mspClientId] ?? ''
      });
      setSimulationId(location.state.simulationId);
      if (!location.state.isProspect) {
        fetchIntakeForClient(location.state.mspClientId)
          .then(intakeForm => {
            setValue('iam', intakeForm.iam * 50);
            setValue('cm', intakeForm.cm * 50);
            setValue('uat', intakeForm.uat * 50);
            setValue('secureArch', intakeForm.secureArch * 50);
            setValue('edr', intakeForm.edr * 50);
            setValue('grc', intakeForm.grc * 50);
            setValue('nip', intakeForm.nip * 50);
            setValue('wf', intakeForm.wf * 50);
            setValue('irm', intakeForm.irm * 50);
            setValue('contentManagement', intakeForm.contentManagement * 50);
            setValue('ti', intakeForm.ti * 50);
          })
          .catch(error => {
            console.error(error);
            toast.error("Error fetching client's intake form");
          });
      }
    }
  }, [location.state, clients]);

  const onSubmit = data => {
    setFormData({
      iam: parseFloat((data.iam * 0.01).toFixed(2)),
      cm: parseFloat((data.configMgmt * 0.01).toFixed(2)),
      uat: parseFloat((data.uat * 0.01).toFixed(2)),
      secureArch: parseFloat((data.secureArch * 0.01).toFixed(2)),
      edr: parseFloat((data.edr * 0.01).toFixed(2)),
      grc: parseFloat((data.grc * 0.01).toFixed(2)),
      nip: parseFloat((data.nip * 0.01).toFixed(2)),
      wf: parseFloat((data.wf * 0.01).toFixed(2)),
      irm: parseFloat((data.irm * 0.01).toFixed(2)),
      contentManagement: parseFloat((data.contentMgmt * 0.01).toFixed(2)),
      ti: parseFloat((data.ti * 0.01).toFixed(2))
    });
  };

  const iamVals = watch('iam', 0);
  const configMgmtVals = watch('configMgmt', 0);
  const uatVals = watch('uat', 0);
  const secureArchVals = watch('secureArch', 0);
  const edrVals = watch('edr', 0);
  const grcVals = watch('grc', 0);
  const nipVals = watch('nip', 0);
  const wfVals = watch('wf', 0);
  const irmVals = watch('irm', 0);
  const contentMgmtVals = watch('contentMgmt', 0);
  const tiVals = watch('ti', 0);

  const downloadReportButton = useCallback(() => {
    downloadReport(simulationId);
  }, [downloadReport, simulationId]);

  useEffect(() => {
    if (isLoading) {
      toast.loading('Generating report...');
    } else {
      toast.dismiss();
    }
  }, [isLoading]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {!hasStartingValues && (
        <Row className="g-3 mb-3">
          <Col xs={6} lg={2}>
            <Form.Check
              inline
              type="radio"
              label="Prospect"
              name="clientType"
              {...register('clientType')}
              onChange={handleRadioChange}
              value="prospect"
              checked={isProspect}
            />
          </Col>
          <Col xs={6} lg={2}>
            <Form.Check
              type="radio"
              label="Existing Client"
              name="clientType"
              {...register('clientType')}
              onChange={handleRadioChange}
              value="client"
              checked={!isProspect}
            />
          </Col>
        </Row>
      )}
      <Row className="g-3 mb-3">
        <Col lg={10}>
          {isProspect ? (
            <Form.Group className="mb-3">
              <Form.Label>Demo</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.prospectName}
                {...register('prospectName')}
                placeholder="Prospect Name"
                readOnly={hasStartingValues}
              />
              <Form.Control.Feedback type="invalid">
                {errors.prospectName?.message}
              </Form.Control.Feedback>
            </Form.Group>
          ) : (
            <Form.Group className="mb-3">
              <Form.Label>Client Name</Form.Label>
              <Controller
                control={control}
                name="clientName"
                render={({ field }) =>
                  hasStartingValues ? (
                    <Form.Control
                      type="text"
                      value={field.value?.label}
                      placeholder="Client Name"
                      readOnly
                    />
                  ) : (
                    <div>
                      <Select
                        classNamePrefix="react-select"
                        {...field}
                        options={Object.entries(clients).map(
                          ([id, client]) => ({
                            value: id,
                            label: client
                          })
                        )}
                        placeholder=""
                      />
                      {errors.clientName && (
                        <div className="invalid-feedback d-block">
                          {errors.clientName?.value?.message}
                        </div>
                      )}
                    </div>
                  )
                }
              />
            </Form.Group>
          )}
        </Col>
      </Row>
      <hr />
      <h1 className="simulator-title">
        Security Controls
        <ControlDescriptionTooltip id="title-tt" size={18}>
          <p>
            While NIST and CMMC include over 80 individual security controls,
            ThreatCaptain has created 11 general security control categories
            that encompass the major themes of those frameworks.
          </p>
          <p>
            Hover over the tooltip icons below to learn more about each
            indivdual security control.
          </p>
        </ControlDescriptionTooltip>
      </h1>
      <h3 className="simulator-subtitle">How are your controls deployed?</h3>
      <Row className="g-3 mb-3">
        <Col lg={4} className="sec-control-col">
          <SecControlIcon icon={iam} value={iamVals} />
          <Form.Group className="mb-3">
            <Form.Label>
              Identity & Access Mgmt <strong>(IAM)</strong>
              <ControlDescriptionTooltip id="iam-tt">
                <p>
                  <strong>Identity & Access Management (IAM):</strong>
                  <br />
                  Controls who can access your systems and data, ensuring that
                  only authorized users have the right permissions.
                </p>
                <ul>
                  <li>
                    Highly Secure: Only authorized users have access, with
                    strong authentication methods and regular reviews of
                    permissions.
                  </li>
                  <li>
                    Moderately Secure: Basic authentication methods are used,
                    and access reviews are conducted occasionally.
                  </li>
                  <li>
                    Passively Secure: Minimal authentication infrequent or no
                    review of access permissions, increasing increasing risk of
                    unauthorized access.
                  </li>
                </ul>
              </ControlDescriptionTooltip>
            </Form.Label>
            <Controller
              name="iam"
              control={control}
              render={({ field }) => <LikelihoodRange {...field} />}
            />
          </Form.Group>
        </Col>
        <Col lg={4} className="sec-control-col">
          <SecControlIcon icon={uat} value={uatVals} />
          <Form.Group className="mb-3">
            <Form.Label>
              User Awareness Training<strong>(UAT)</strong>
              <ControlDescriptionTooltip id="uat-tt">
                <p>
                  <strong>User Awareness Training (UAT):</strong>
                  <br />
                  Educates employees about cybersecurity best practices and how
                  to recognize and respond to potential threats.
                </p>
                <ul>
                  <li>
                    Highly Secure: Employees are regularly trained and tested on
                    cybersecurity best practices and threat recognition.
                  </li>
                  <li>
                    Moderately Secure: Employees receive occasional training,
                    but it may not cover the latest threats.
                  </li>
                  <li>
                    Passively Secure: Limited or no training, leaving employees
                    unprepared to recognize and respond to threats.
                  </li>
                </ul>
              </ControlDescriptionTooltip>
            </Form.Label>
            <Controller
              name="uat"
              control={control}
              render={({ field }) => <LikelihoodRange {...field} />}
            />
          </Form.Group>
        </Col>
        <Col lg={4} className="sec-control-col">
          <SecControlIcon icon={secArch} value={secureArchVals} />
          <Form.Group className="mb-3">
            <Form.Label>
              Secure Architecture & Segmentation <strong>(SA)</strong>
              <ControlDescriptionTooltip id="securearch-tt">
                <p>
                  <strong>Secure Architecture & Segmentation (SA):</strong>
                  <br />
                  Designs and divides the network into segments to limit the
                  spread of attacks and protect sensitive data.
                </p>
                <ul>
                  <li>
                    Highly Secure: Network is thoroughly segmented, limiting the
                    spread of attacks and protecting sensitive data.
                  </li>
                  <li>
                    Moderately Secure: Some network segmentation is in place,
                    but not comprehensive.
                  </li>
                  <li>
                    Passively Secure: Little to no network segmentation, making
                    it easier for attackers to move laterally across systems.
                  </li>
                </ul>
              </ControlDescriptionTooltip>
            </Form.Label>
            <Controller
              name="secureArch"
              control={control}
              render={({ field }) => <LikelihoodRange {...field} />}
            />
          </Form.Group>
        </Col>
        <Col lg={4} className="sec-control-col">
          <SecControlIcon icon={edr} value={edrVals} />
          <Form.Group className="mb-3">
            <Form.Label>
              Endpoint Detection & Response <strong>(EDR)</strong>
              <ControlDescriptionTooltip id="edr-tt">
                <p>
                  <strong>Endpoint Detection & Response (EDR):</strong>
                  <br />
                  Provides advanced threat detection and response capabilities
                  to protect endpoints (computers, devices) from sophisticated
                  malware and attacks.
                </p>
                <ul>
                  <li>
                    Highly Secure: Advanced tools are in place for real-time
                    threat detection and response, with continuous monitoring.
                  </li>
                  <li>
                    Moderately Secure: Basic EDR and antivirus solutions are
                    used, with periodic monitoring.
                  </li>
                  <li>
                    Passively Secure: Minimal endpoint protection, relying on
                    outdated or ineffective antivirus solutions.
                  </li>
                </ul>
              </ControlDescriptionTooltip>
            </Form.Label>
            <Controller
              name="edr"
              control={control}
              render={({ field }) => <LikelihoodRange {...field} />}
            />
          </Form.Group>
        </Col>
        <Col lg={4} className="sec-control-col">
          <SecControlIcon icon={ti} value={tiVals} />
          <Form.Group className="mb-3">
            <Form.Label>
              Threat Intelligence <strong>(TI)</strong>
              <ControlDescriptionTooltip id="ti-tt">
                <p>
                  <strong>Threat Intelligence (TI):</strong>
                  <br />
                  Collects and analyzes information about current and emerging
                  threats to help organizations proactively defend against cyber
                  attacks.
                </p>
                <ul>
                  <li>
                    Highly Secure: Continuous collection and analysis of threat
                    data to proactively defend against emerging attacks.
                  </li>
                  <li>
                    Moderately Secure: Periodic threat intelligence updates, but
                    may not cover the latest threats.
                  </li>
                  <li>
                    Passively Secure: Limited or no threat intelligence, leaving
                    the organization unaware of current and emerging threats.
                  </li>
                </ul>
              </ControlDescriptionTooltip>
            </Form.Label>
            <Controller
              name="ti"
              control={control}
              render={({ field }) => <LikelihoodRange {...field} />}
            />
          </Form.Group>
        </Col>
        <Col lg={4} className="sec-control-col">
          <SecControlIcon icon={nip} value={nipVals} />
          <Form.Group className="mb-3">
            <Form.Label>
              Network Intrusion Prevention <strong>(NIP)</strong>
              <ControlDescriptionTooltip id="nip-tt">
                <p>
                  <strong>Network Intrusion Prevention (NIP):</strong>
                  <br />
                  Monitors network traffic to detect and prevent malicious
                  activities, stopping threats before they can cause damage.
                </p>
                <ul>
                  <li>
                    Highly Secure: Advanced NIP systems monitor and block
                    malicious activities in real-time.
                  </li>
                  <li>
                    Moderately Secure: Basic NIP systems provide some
                    protection, but may miss sophisticated attacks.
                  </li>
                  <li>
                    Passively Secure: Minimal or no NIP systems, leaving the
                    network vulnerable to intrusions.
                  </li>
                </ul>
              </ControlDescriptionTooltip>
            </Form.Label>
            <Controller
              name="nip"
              control={control}
              render={({ field }) => <LikelihoodRange {...field} />}
            />
          </Form.Group>
        </Col>
        <Col lg={4} className="sec-control-col">
          <SecControlIcon icon={irm} value={irmVals} />
          <Form.Group className="mb-3">
            <Form.Label>
              Information Rights Management <strong>(IRM)</strong>
              <ControlDescriptionTooltip id="irm-tt">
                <p>
                  <strong>Information Rights Management (IRM):</strong>
                  <br />
                  Controls how information is accessed, used, and shared, even
                  after it leaves the organization's boundaries.
                </p>
                <ul>
                  <li>
                    Highly Secure: Strict control over how information is
                    accessed, used, and shared, even outside the organization.
                  </li>
                  <li>
                    Moderately Secure: Basic IRM measures are implemented, but
                    not comprehensive.
                  </li>
                  <li>
                    Passively Secure: Limited or no IRM, increasing the risk of
                    unauthorized access and data leakage.
                  </li>
                </ul>
              </ControlDescriptionTooltip>
            </Form.Label>
            <Controller
              name="irm"
              control={control}
              render={({ field }) => <LikelihoodRange {...field} />}
            />
          </Form.Group>
        </Col>
        <Col lg={4} className="sec-control-col">
          <SecControlIcon icon={wf} value={wfVals} />
          <Form.Group className="mb-3">
            <Form.Label>
              Web Filtering <strong>(WF)</strong>
              <ControlDescriptionTooltip id="wf-tt">
                <p>
                  <strong>Web Filtering (WF):</strong>
                  <br />
                  Blocks access to malicious or inappropriate websites to
                  protect users from online threats and ensure a safe browsing
                  environment.
                </p>
                <ul>
                  <li>
                    Highly Secure: Robust web filtering blocks access to
                    malicious and inappropriate sites, protecting users.
                  </li>
                  <li>
                    Moderately Secure: Basic web filtering is in place, but may
                    not cover all threats.
                  </li>
                  <li>
                    Passively Secure: Little or no web filtering, exposing users
                    to a wide range of online threats.
                  </li>
                </ul>
              </ControlDescriptionTooltip>
            </Form.Label>
            <Controller
              name="wf"
              control={control}
              render={({ field }) => <LikelihoodRange {...field} />}
            />
          </Form.Group>
        </Col>
        <Col lg={4} className="sec-control-col">
          <SecControlIcon icon={content} value={contentMgmtVals} />
          <Form.Group className="mb-3">
            <Form.Label>
              <strong>
                Content Management <strong>(CtM)</strong>
              </strong>
              <ControlDescriptionTooltip id="cm-tt">
                <p>
                  Content Management (CtM):
                  <br />
                  Manages the creation, storage, and control of digital content
                  to ensure its integrity, security, and accessibility.
                </p>
                <ul>
                  <li>
                    Highly Secure: Effective management of digital content
                    ensures its security, integrity, and accessibility.
                  </li>
                  <li>
                    Moderately Secure: Basic content management practices are
                    followed, but may lack thoroughness.
                  </li>
                  <li>
                    Passively Secure: Minimal content management, leading to
                    potential data integrity and accessibility issues.
                  </li>
                </ul>
              </ControlDescriptionTooltip>
            </Form.Label>
            <Controller
              name="contentMgmt"
              control={control}
              render={({ field }) => <LikelihoodRange {...field} />}
            />
          </Form.Group>
        </Col>
        <Col lg={4} className="sec-control-col">
          <SecControlIcon icon={cm} value={configMgmtVals} />
          <Form.Group className="mb-3">
            <Form.Label>
              Configuration Mgmt <strong>(CM)</strong>
              <ControlDescriptionTooltip id="cm-tt">
                <p>
                  <strong>Configuration Management (CM):</strong>
                  <br />
                  Maintains the integrity of hardware, software, and system
                  settings by managing and documenting changes to ensure
                  security and compliance.
                </p>
                <ul>
                  <li>
                    Highly Secure: All system changes are documented, reviewed,
                    and tested before implementation, ensuring secure
                    configurations.
                  </li>
                  <li>
                    Moderately Secure: Most changes are documented and reviewed,
                    but testing is less rigorous.
                  </li>
                  <li>
                    Passively Secure: Changes are rarely documented or reviewed,
                    leading to potential misconfigurations and vulnerabilities.
                  </li>
                </ul>
              </ControlDescriptionTooltip>
            </Form.Label>
            <Controller
              name="configMgmt"
              control={control}
              render={({ field }) => <LikelihoodRange {...field} />}
            />
          </Form.Group>
        </Col>
        <Col lg={4} className="sec-control-col">
          <SecControlIcon icon={grc} value={grcVals} />
          <Form.Group className="mb-3">
            <Form.Label>
              Governance, Risk Management, & Compliance/Integrated Risk
              Management <strong>(GRC)</strong>
              <ControlDescriptionTooltip id="gcr-tt">
                <p>
                  <strong>
                    Governance, Risk Management, & Compliance/Integrated Risk
                    Management (GRC):
                  </strong>
                  <br />
                  Frameworks and processes for managing organizational
                  governance, assessing and mitigating risks, and ensuring
                  compliance with regulations.
                </p>
                <ul>
                  <li>
                    Highly Secure: Comprehensive GRC/IRM processes ensure strict
                    adherence to regulations and proactive risk management.
                  </li>
                  <li>
                    Moderately Secure: Basic GRC/IRM processes are in place, but
                    may lack thoroughness.
                  </li>
                  <li>
                    Passively Secure: Limited or no GRC/IRM processes, leading
                    to potential compliance issues and unmanaged risks.
                  </li>
                </ul>
              </ControlDescriptionTooltip>
            </Form.Label>
            <Controller
              name="grc"
              control={control}
              render={({ field }) => <LikelihoodRange {...field} />}
            />
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Label>&nbsp;</Form.Label>
          <Button type="submit" variant="primary" className="w-100 mb-3">
            Assess Likelihood
          </Button>
          <Button
            type="button"
            variant="secondary"
            className="w-100"
            disabled={!hasResult}
            onClick={downloadReportButton}
          >
            <FontAwesomeIcon icon="file" className="d-inline-block me-2" />
            Generate Risk Report
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
LikelihoodSimulatorForm.propTypes = {
  setFormData: PropTypes.func.isRequired,
  simulationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setSimulationId: PropTypes.func.isRequired,
  hasResult: PropTypes.bool.isRequired
};
export default LikelihoodSimulatorForm;
