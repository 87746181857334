import React, { useRef, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const useConfirmDialog = () => {
  const promiseResolveRef = useRef();
  const [show, setShow] = useState(false);
  const [dialogProps, setDialogProps] = useState({});

  const onClose = () => {
    setShow(false);
    if (promiseResolveRef.current) {
      promiseResolveRef.current(false);
    }
  };

  const onConfirm = () => {
    setShow(false);
    if (promiseResolveRef.current) {
      promiseResolveRef.current(true);
    }
  };

  const ConfirmDialog = () => {
    const { message, title, acceptButton, rejectButton } = dialogProps;
    return (
      <Modal show={show} onHide={onClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button variant="falcon-primary" onClick={onClose}>
            {rejectButton ?? 'Cancel'}
          </Button>
          <Button variant="falcon-danger" onClick={onConfirm}>
            {acceptButton ?? 'OK'}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const confirm = (
    message,
    title = 'Confirmation',
    acceptButton = 'OK',
    rejectButton = 'Cancel'
  ) => {
    setDialogProps({ message, title, acceptButton, rejectButton });
    setShow(true);
    return new Promise(resolve => (promiseResolveRef.current = resolve));
  };

  return { ConfirmDialog, confirm };
};

export default useConfirmDialog;
