import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const useIntakeDocuments = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [documents, setDocuments] = useState([]);

  const { getAccessTokenSilently } = useAuth0();

  const fetchIntakeDocuments = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/clients/intake-documents`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      if (!response.ok) {
        setError('Network response was not ok');
      }
      const data = await response.json();
      setDocuments(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchIntakeDocuments, isLoading, error, documents };
};
export default useIntakeDocuments;
