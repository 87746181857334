import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
import { Row, Col } from 'react-bootstrap';
import {
  companySecurityOptions,
  riskToleranceOptions,
  securityPostureOptions,
  complianceOptions,
  breachHistoryOptions,
  securityTeamOptions
} from 'data/clients/intakeWizardData';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

const BusinessForm = ({ register, errors, intake, setValue }) => {
  useEffect(() => {
    if (intake) {
      setValue(
        'criticalLossThreshold',
        currencyFormatter.format(intake.criticalLossThreshold || 0)
      );
      setValue(
        'emergencyResponseFund',
        currencyFormatter.format(intake.emergencyResponseFund || 0)
      );
      setValue('breachHistory', intake.breachHistory || '');
      setValue('companySecurity', intake.companySecurity || '');
      setValue('riskTolerance', intake.riskTolerance || '');
      setValue('securityPosture', intake.securityPosture || '');
      setValue('compliance', intake.compliance || '');
      setValue('securityTeam', intake.securityTeam || '');
    } else {
      setValue('criticalLossThreshold', currencyFormatter.format(0));
      setValue('emergencyResponseFund', currencyFormatter.format(0));
    }
  }, [intake, setValue]);

  return (
    <>
      <h4>Business Insights</h4>
      <Row className="g-4 mb-3">
        <p className="subheader">
          Your leadership's perspective is crucial in shaping your
          organization's cybersecurity strategy. Please share their views to
          help us better taylor our approach.
        </p>
      </Row>
      <Row className="g-4 mb-3">
        <Col md={6}>
          <WizardInput
            type="text"
            label="Critical Loss Threshold"
            sublabel="At what financial loss point would your company no longer be able to operate?"
            name="criticalLossThreshold"
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              ...register('criticalLossThreshold', {
                onBlur: e => {
                  const rawValue = e.target.value.replace(/[^0-9.]/g, '');
                  e.target.value = currencyFormatter.format(rawValue);
                },
                setValueAs: value => {
                  if (!value) {
                    return '0';
                  }
                  return (value + '').replace(/[^0-9.]/g, '');
                }
              })
            }}
          />
        </Col>
        <Col md={6}>
          <WizardInput
            type="text"
            label="Emergency Response Fund amount"
            sublabel="What is the amount you can allocate to resolve an issue quickly?"
            name="emergencyResponseFund"
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              ...register('emergencyResponseFund', {
                onBlur: e => {
                  const rawValue = e.target.value.replace(/[^0-9.]/g, '');
                  e.target.value = currencyFormatter.format(rawValue);
                },
                setValueAs: value => {
                  if (!value) {
                    return '0';
                  }
                  return (value + '').replace(/[^0-9.]/g, '');
                }
              })
            }}
          />
        </Col>
      </Row>
      <Row className="g-4 mb-3">
        <Col>
          <WizardInput
            type="select"
            label="Breach History:"
            sublabel="Has your company experienced any breaches in the last 3 years?"
            name="breachHistory"
            errors={errors}
            options={breachHistoryOptions}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              ...register('breachHistory')
            }}
          />
        </Col>
      </Row>
      <Row className="g-4 mb-3">
        <Col>
          <WizardInput
            type="select"
            label="Leadership’s Perception of Security:"
            sublabel="How confident is your leadership in the current security measures of your organization?"
            name="companySecurity"
            errors={errors}
            options={companySecurityOptions}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              ...register('companySecurity')
            }}
          />
        </Col>
      </Row>
      <Row className="g-4 mb-3">
        <Col>
          <WizardInput
            type="select"
            label="Leadership’s Risk Tolerance:"
            sublabel="What level of risk is your leadership willing to accept when it comes to cybersecurity threats?"
            name="riskTolerance"
            errors={errors}
            options={riskToleranceOptions}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              ...register('riskTolerance')
            }}
          />
        </Col>
      </Row>
      <Row className="g-4 mb-3">
        <Col>
          <WizardInput
            type="select"
            label="Desired Security Posture:"
            sublabel="What level of security does your leadership aim for within your organization?"
            name="securityPosture"
            errors={errors}
            options={securityPostureOptions}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              ...register('securityPosture')
            }}
          />
        </Col>
      </Row>
      <Row className="g-4 mb-3">
        <Col>
          <WizardInput
            type="select"
            label="Leadership’s Compliance Priorities:"
            sublabel="How important is compliance to your leadership in your overall cybersecurity strategy?"
            name="compliance"
            errors={errors}
            options={complianceOptions}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              ...register('compliance')
            }}
          />
        </Col>
      </Row>
      <Row className="g-4 mb-3">
        <Col>
          <WizardInput
            type="select"
            label="Tell us about the Security Team:"
            sublabel="What is the structure of your security team?"
            name="securityTeam"
            errors={errors}
            options={securityTeamOptions}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              ...register('securityTeam')
            }}
          />
        </Col>
      </Row>
    </>
  );
};

BusinessForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired,
  intake: PropTypes.object.isRequired
};

export default BusinessForm;
