export const companySecurityOptions = [
  'Very Secure',
  'Moderately Secure',
  'Slightly Risk',
  'Not Secure'
];

export const riskToleranceOptions = [
  'High Risk Tolerance',
  'Moderate Risk Tolerance',
  'Low Risk Tolernace',
  'Zero Risk Tolerance'
];

export const securityPostureOptions = [
  'Proactive and Advanced',
  'Balanced and Comprehensive',
  'Basic and Cost-Effective',
  'Minimal and Compliant'
];

export const complianceOptions = [
  'Critical Priority',
  'Important but Flexible',
  'Necessary for Minimum Standards',
  'Low Priority'
];

export const securityTeamOptions = [
  'We have a dedicated security team with a CISO',
  'We have a dedicated security team that reports to the IT department',
  'We do not have a dedicated security team'
];

export const breachHistoryOptions = [
  'Yes, our company has experienced a breach',
  'No, our company has not experienced any breaches'
];

export const incidentResponseOptions = [
  'Multiple times per year',
  'Once per year',
  'We do not practice Incident Response'
];
export const disasterRecoveryOptions = [
  'Multiple times per year',
  'Once per year',
  'We do not practice Disaster Recovery'
];
export const tabletopOptions = [
  'Multiple times per year',
  'Once per year',
  'We do not practice Tabletops/WarGames'
];
