import React from 'react';
import App from 'App';
import paths, { rootPaths } from './paths';
import { Navigate, createBrowserRouter } from 'react-router-dom';

import Clients from 'components/clientsDashboard/Clients';
import FeedbackForm from 'components/feedbackForm/FeedbackForm';
import Billing from 'components/billing/Billing';
import BillingReturn from 'components/billing/BillingReturn';
import { AddClient, UpdateClient } from 'components/clients/ClientIntake';
import EditOrganization from 'components/clients/EditOrganization';
import Prospects from 'components/pages/Prospects';
import Insights from 'components/pages/Insights';
import Reports from 'components/reports/Reports';
import RiskAnalysis from 'components/pages/RiskAnalysis';
import ImpactSimulator from 'components/simulator/impact/ImpactSimulator';
import ImpactSimulatorExport from 'components/simulator/impact/export/ImpactSimulatorExport';
import LikelihoodSimulator from 'components/simulator/likelihood/LikelihoodSimulator';
// import AddClient from 'components/clients/AddClient';
// import UpdateClient from 'components/clients/UpdateClient';
import ClientDetails from 'components/clients/ClientDetails/ClientDetails';
import AuthGuard from 'components/auth0/AuthGuard';
import AddMsp from 'components/pages/msp/AddMsp';
import AccountSettingsPage from 'components/accountSettings/AccountSettingsPage';
import Academy from 'components/academy/index';
import CertificatePage from 'helpers/certificatePage';
import Signup from 'components/auth0/Signup.js';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import ErrorLayout from 'layouts/ErrorLayout';
import MainLayout from 'layouts/MainLayout';
import Dashboard from 'components/dashboard/index';
// import Landing from 'components/pages/landing/Landing';
import CallbackPage from 'components/pages/Callback';

const routes = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: <AuthGuard component={MainLayout} />,
        children: [
          {
            index: true,
            element: <AuthGuard component={Dashboard} />
          },
          {
            path: rootPaths.feedback,
            element: <AuthGuard component={FeedbackForm} />
          },
          {
            path: paths.accountSettings,
            element: <AuthGuard component={AccountSettingsPage} />
          },
          {
            path: paths.academy,
            element: <AuthGuard component={Academy} />
          },
          {
            path: paths.certificatePage,
            element: <AuthGuard component={CertificatePage} />
          },
          {
            path: rootPaths.clients,
            element: <AuthGuard component={Clients} />
          },
          {
            path: paths.clientDetails,
            element: <AuthGuard component={ClientDetails} />
          },
          {
            path: paths.addClient,
            element: <AuthGuard component={AddClient} />
          },
          {
            path: paths.updateClient,
            element: <AuthGuard component={EditOrganization} />
          },
          {
            path: paths.clientIntake,
            element: <AuthGuard component={UpdateClient} />
          },
          {
            path: paths.prospects,
            element: <AuthGuard component={Prospects} />
          },
          {
            path: paths.insights,
            element: <AuthGuard component={Insights} />
          },
          {
            path: paths.reports,
            element: <AuthGuard component={Reports} />
          },
          {
            path: paths.riskAnalysis,
            element: <AuthGuard component={RiskAnalysis} />
          },
          {
            path: paths.impactSimulator,
            element: <AuthGuard component={ImpactSimulator} />
          },
          {
            path: paths.impactSimulatorExport,
            element: <AuthGuard component={ImpactSimulatorExport} />
          },
          {
            path: paths.likelihoodSimulator,
            element: <AuthGuard component={LikelihoodSimulator} />
          },
          {
            path: paths.billing,
            element: <AuthGuard component={Billing} />
          },
          {
            path: paths.billingReturn,
            element: <AuthGuard component={BillingReturn} />
          }
        ]
      },
      {
        path: rootPaths.errorsRoot,
        element: <ErrorLayout />,
        children: [
          {
            path: paths.error404,
            element: <Error404 />
          },
          {
            path: paths.error500,
            element: <Error500 />
          }
        ]
      },
      {
        path: paths.callback,
        element: <CallbackPage />
      },
      {
        path: paths.addMsp,
        element: <AuthGuard component={AddMsp} goToMSP={false} />
      },
      {
        path: '*',
        element: <Navigate to={paths.error404} replace />
      },
      {
        path: paths.signup,
        element: <Signup />
      }
    ]
  }
];

export const router = createBrowserRouter(routes, {
  basename: process.env.PUBLIC_URL
});

export default routes;
