import React, { useEffect, useState } from "react";
import '../../../assets/scss/theme/page-styles/_clientDetails.scss';
import { Button, Card, Form, ListGroup } from "react-bootstrap";

import ControlsForm from "../ClientIntakeWizard/ControlsForm";
import useReport from 'hooks/useReport';

import PropTypes from 'prop-types';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { RadarChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { useAppContext } from 'providers/AppProvider';
import Flex from "components/common/Flex";
import useSimulations from "hooks/useSimulations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SecControlIcon from 'components/simulator/likelihood/SecControlIcon';
// Icons
import iam from 'assets/img/security-controls/iam.png';
import uat from 'assets/img/security-controls/uat.png';
import secArch from 'assets/img/security-controls/secArch.png';
import edr from 'assets/img/security-controls/edr.png';
import ti from 'assets/img/security-controls/ti.png';
import nip from 'assets/img/security-controls/nip.png';
import irm from 'assets/img/security-controls/irm.png';
import wf from 'assets/img/security-controls/wf.png';
import content from 'assets/img/security-controls/content.png';
import cm from 'assets/img/security-controls/cm.png';
import grc from 'assets/img/security-controls/grc.png';
import securityPostureCalculator from "helpers/securityPostureCalculator";
import 'assets/scss/theme/page-styles/_clientWidgets.scss';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  RadarChart,
  CanvasRenderer,
  LegendComponent
]);

const ClientWidjets = ({ client, intake, goToIntakeForm }) => {

  const { listSimulations } = useSimulations();
  const { downloadReport, isLoading } = useReport();

  const [simulators, setSimulators] = useState(null);
  const [financialCost, setFinancialCost] = useState(null);
  const [annualCost, setAnnualCost] = useState(null);

  const fakeClient = {};
  const prospect = client.prospect;
  const securityPosture = securityPostureCalculator(intake);

  const getProcessValue = (value) => {
    if (value == null) return 0;

    if (value == 0) return 0;
    else if (value == 1) return 50;
    else return 100;
  }

  useEffect(() => {
    const gatherSimulations = async () => {
      if (client) {
        let simulations = await listSimulations(client.id);
        simulations = simulations.sort((a, b) => b.version - a.version);
        setSimulators(simulations);
  
        const newestSimulation = simulations.reduce((maxSimulation, currentSimulation) => {
          return currentSimulation.version > maxSimulation.version ? currentSimulation : maxSimulation;
        }, simulations[0]);

        if (newestSimulation?.impactSimulation?.impactResult?.averageBreachCost) {
          setFinancialCost(newestSimulation.impactSimulation.impactResult.averageBreachCost);
        }
  
        if (newestSimulation?.likelihoodSimulation?.annualResidualRisk) {
          setAnnualCost(newestSimulation.likelihoodSimulation.annualResidualRisk);
        }
      }
    }

    gatherSimulations();
  }, [client]);

  const downloadReportButton = (simulationId) => {
    downloadReport(simulationId);
  }

  const graphData = {
    indicators: [
      { name: 'Cyber Policy', max: 5 },
      { name: 'Training Scores', max: 5 },
      { name: 'Leadership', max: 5 },
      { name: 'Compliance', max: 5 },
      { name: 'Security Controls', max: 5 },
      { name: 'Risk Mitigation\nDocumentation', max: 5 }
    ],
    series: [
      securityPosture
    ]
  };

  const getOption = () => {
    // Create a new copy of the graphData.indicators to modify it
    const modifiedIndicators = graphData.indicators.map((indicator, index) => {
      return {
        ...indicator,
        axisLabel: {
          show: index === 0, // Change the index to the one you want to show the label for
          formatter: (value) => `${value}`,
          color: '#000'
        }
      };
    });
  
    return {
      title: {
        text: graphData.title
      },
      tooltip: {
        formatter: (params) => {
          let tooltipText = '';
          graphData.indicators.forEach((indicator, index) => {
            tooltipText += `${indicator.name}: ${params.value[index]}<br/>`;
          });
          return tooltipText;
        }
      },
      radar: {
        indicator: modifiedIndicators, // Use the modified indicators with axisLabel
        splitNumber: 5, // Adjust based on your scale (5 means values 1, 2, 3, 4, 5)
        axisLine: {
          show: true // Show axis lines (optional)
        },
        splitLine: {
          lineStyle: {
            color: '#ccc', // Color of the background circles (customize as needed)
            width: 1
          }
        },
        splitArea: {
          show: true,
          areaStyle: {
            color: ['rgba(255, 255, 255, 0.1)', 'rgba(200, 200, 200, 0.1)'] // Optional: alternating area colors for visual clarity
          }
        },
        axisLabel: {
          show: true, // You can keep this true to show the overall axis label for the selected axis
          formatter: (value) => `${value}`, // Show scale values (optional)
          color: '#000' // Customize color of scale numbers
        }
      },
      series: [{
        type: 'radar',
        data: graphData.series.map(seriesData => ({
          value: seriesData,
          areaStyle: { // Add areaStyle here to fill the radar graph
            normal: {
              color: '#3070C5' // Change this to your desired fill color
            }
          }
        }))
      }]
    };
  };

  const formatCurrency = (value) => {
    if (value >= 1e9) {
        return (value / 1e9).toFixed(2) + 'B'; // Format as billions if 8 digits or more
    } else {
        return '$' + value.toLocaleString(undefined, { 
            minimumFractionDigits: 0, 
            maximumFractionDigits: 0 
        });
    }
};

  return (
    <>
      <div className="client-details-grid-parent">

        {/* Control Center Card */}
        <div className="client-details-grid-item item1">
          <Card className="text-center mt-3">
          <Card.Header className="fw-bold d-flex justify-content-between align-items-center" style={{ backgroundColor: '#F9FAFD' }}>
            <span>Control Center</span>
            <Button onClick={goToIntakeForm} variant="falcon-default">
              <FontAwesomeIcon 
                className="me-1"
                variant="falcon-default"
                size="sm"
                icon="pencil-alt"
              ></FontAwesomeIcon>
              <span className="ms-1">Edit Controls</span>
            </Button>
          </Card.Header>
            <Card.Body className={`${(prospect === true) ? 'add-blur' : ''}`}>
            <Flex alignItems="center" justifyContent="space-between" className="mb-3">
              <span className="fa-stack fa-lg">
                <SecControlIcon icon={iam} value={getProcessValue((intake?.iam ? intake.iam : 0))} size={45} imgSize={25} />
              </span>
              <h5 className="fs-9 text-600 mb-0 ps-1 text-start">
                <span>Identity & Access Management</span>
                <br />
                <span className="text-start"><b>(IAM)</b></span>
              </h5>
            </Flex>
              <Flex alignItems="center" className="mb-3 text-start">
                <span className="fa-stack fa-lg">
                  <SecControlIcon icon={edr} value={getProcessValue((intake?.edr ? intake.edr : 0))} size={45} imgSize={25} />
                </span>
                <h5 className="fs-9 text-600 mb-0 ps-1 text-start">
                  Endpoint Detection & Response
                  <br />
                  <span className="text-start"><b>(EDR)</b></span>
                </h5>
              </Flex>
              <Flex alignItems="center" className="mb-3">
                <span className="fa-stack fa-lg">
                  <SecControlIcon icon={irm} value={getProcessValue((intake?.irm ? intake.irm : 0))} size={45} imgSize={25} />
                </span>
                <h5 className="fs-9 text-600 mb-0 ps-1 text-start">
                  Information Rights Management
                  <span className="text-start"><b>(IRM)</b></span>
                </h5>
              </Flex>
              <Flex alignItems="center" className="mb-3 text-start">
                <span className="fa-stack fa-lg">
                  <SecControlIcon icon={cm} value={getProcessValue((intake?.cm ? intake.cm : 0))} size={45} imgSize={25} />
                </span>
                <h5 className="fs-9 text-600 mb-0 ps-1 text-start">
                  Configuration Management
                  <span className="text-start"><b>(CM)</b></span>
                </h5>
              </Flex>
              <Flex alignItems="center" className="mb-3 text-start">
                <span className="fa-stack fa-lg">
                  <SecControlIcon icon={uat} value={getProcessValue((intake?.uat ? intake.uat : 0))} size={45} imgSize={35} />
                </span>
                <h5 className="fs-9 text-600 mb-0 ps-1 text-start">
                  User Awareness Testing
                  <span className="text-start"><b>(UAT)</b></span>
                </h5>
              </Flex>
              <Flex alignItems="center" className="mb-3 text-start">
                <span className="fa-stack fa-lg">
                  <SecControlIcon icon={ti} value={getProcessValue((intake?.ti ? intake.ti : 0))} size={45} imgSize={35} />
                </span>
                <h5 className="fs-9 text-600 mb-0 ps-1 text-start">
                  Threat Intelligence
                  <span className="text-start"><b>(TI)</b></span>
                </h5>
              </Flex>
              <Flex alignItems="center" className="mb-3 text-start">
                <span className="fa-stack fa-lg">
                  <SecControlIcon icon={wf} value={getProcessValue((intake?.wf ? intake.wf : 0))} size={45} imgSize={30} />
                </span>
                <h5 className="fs-9 text-600 mb-0 ps-1 text-start">
                  Web Filtering
                  <span className="text-start"><b>(WF)</b></span>
                </h5>
              </Flex>
              <Flex alignItems="center" className="mb-3 text-start">
                <span className="fa-stack fa-lg">
                  <SecControlIcon icon={grc} value={getProcessValue((intake?.grc ? intake.grc : 0))} size={45} imgSize={25} />
                </span>
                <h5 className="fs-9 text-600 mb-0 ps-1 text-start">
                  Governance, Risk Management,
                  <br /> & Compliance/Integrated Risk
                  <br /> Management
                  <span className="text-start"><b>(GRC)</b></span>
                </h5>
              </Flex>
              <Flex alignItems="center" className="mb-3 text-start">
                <span className="fa-stack fa-lg">
                  <SecControlIcon icon={secArch} value={getProcessValue((intake?.secureArch ? intake.secureArch : 0))} size={45} imgSize={25} />
                </span>
                <h5 className="fs-9 text-600 mb-0 ps-1 text-start">
                  Secure  Architecture
                  <br />
                  
                  <span className="text-start"><b>(SA)</b></span>
                </h5>
              </Flex>
              <Flex alignItems="center" className="mb-3 text-start">
                <span className="fa-stack fa-lg">
                  <SecControlIcon icon={nip} value={getProcessValue((intake?.nip ? intake.nip : 0))} size={45} imgSize={25} />
                </span>
                <h5 className="fs-9 text-600 mb-0 ps-1 text-start">
                  Network Intrusion Prevention
                  <br />
                  
                  <span className="text-start"><b>(NIP)</b></span>
                </h5>
              </Flex>
              <Flex alignItems="center" className="mb-3 text-start">
                <span className="fa-stack fa-lg">
                  <SecControlIcon icon={content} value={getProcessValue((intake?.contentManagement ? intake.contentManagement : 0))} size={45} imgSize={25} />
                </span>
                <h5 className="fs-9 text-600 mb-0 ps-1 text-start">
                Content Management
                  <br />
                  <span className="text-start"><b>(CTM)</b></span>
                </h5>
              </Flex>
            </Card.Body>
          </Card>
        </div>

        {/* Financial Impact Card */}
        <div className="client-details-grid-item item2 mt-3">
          <Card className="text-center" style={{ height: '100%' }}>
            <Card.Header className="fw-bold" style={{ backgroundColor: '#F9FAFD' }}>
              Critical Threshold Amount
            </Card.Header>
            <Card.Body className={`${(prospect === true) ? 'add-blur' : ''}`}>
              <Card.Text style={{ fontSize: '2rem', fontWeight: 'bold' }}>
                {intake?.criticalLossThreshold ? formatCurrency(intake.criticalLossThreshold) : 'N/A'}
              </Card.Text>
            </Card.Body>
          </Card>
        </div>

        {/* Annual Risk Cost Card */}
        <div className="item3 my-2">
          <Card className="text-center" style={{ height: '100%' }}>
            <Card.Header className="fw-bold" style={{ backgroundColor: '#F9FAFD' }}>
              Emergency Fund Amount
            </Card.Header>
            <Card.Body className={`${(prospect === true) ? 'add-blur' : ''}`}>
              <Card.Text style={{ fontSize: '2rem', fontWeight: 'bold' }}>
              {intake?.emergencyResponseFund ? formatCurrency(intake.emergencyResponseFund) : 'N/A'}
              </Card.Text>
            </Card.Body>
          </Card>
        </div>

        {/* Security Posture Card */}
        <div className="item4">
          <Card className="text-center mt-3">
            <Card.Header className="fw-bold" style={{ backgroundColor: '#F9FAFD' }}>
              Security Posture
            </Card.Header>
            <Card.Body className={`${(prospect === true) ? 'add-blur' : ''}`}>
              <ReactEChartsCore
                echarts={echarts}
                option={getOption()}
                notMerge={true}
                lazyUpdate={true}
                theme={"theme_name"}
              />
            </Card.Body>
          </Card>
        </div>

        {/* Reports Table Card */}
        <div className="item5">
          <Card style={{}}>
            <Card.Header className="fw-bold" style={{ backgroundColor: '#F9FAFD' }}>
              Reports
            </Card.Header>
            <Card.Body style={{ overflowY: 'auto', padding: 0, maxHeight: '40vh' }} className={`${(prospect === true) ? 'add-blur' : ''}`}>
              <ListGroup variant="flush">
                {simulators && (
                  <ListGroup>
                    {simulators && simulators.length > 0 ? (
                      simulators.map((item, index) => (
                        <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center">
                          <div>
                            <span>{`${item.clientName} - ${item.formattedCreatedAt}`}</span>
                            <div>{/* Add your additional field here, for example: */}</div>
                            <small className="text-muted">
                              {item.likelihoodSimulation && item.likelihoodSimulation.annualResidualRisk !== undefined
                                ? `${item.likelihoodSimulation.annualResidualRisk.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`
                                : 'No Annual Risk Cost Available'}
                            </small>
                          </div>
                          {item.likelihoodSimulation && item.likelihoodSimulation.annualResidualRisk !== undefined 
                          ? <Button variant="falcon-primary" onClick={() => downloadReportButton(item.id)}>Generate</Button> : 
                          <><p className="fw-bold">Incomplete</p></>}
                          
                        </ListGroup.Item>
                      ))
                    ) : (
                      <p className="text-muted text-center fw-bold">No simulations done for this Client yet.</p>
                    )}
                  </ListGroup>
                )}
              </ListGroup>

            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

ClientWidjets.propTypes = {
  client: PropTypes.object.isRequired,
  intake: PropTypes.object
};

export default ClientWidjets;