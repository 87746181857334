import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Form,
  Col,
  InputGroup,
  Card,
  Accordion,
  Table,
  Badge
} from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import useIntakePolicies from 'hooks/useIntakePolicies';
import Spinner from 'react-bootstrap/Spinner';
import CheckWithIndeterminate from 'components/common/FormCheckWithIndeterminate';

import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Filter to only allow unique values in an array
function arrayUnique(value, index, array) {
  return array.indexOf(value) === index;
}

const PoliciesForm = ({ control, intake, setValue }) => {
  const [searchString, setSearchString] = useState('');
  const { fetchIntakePolicies, isLoading, policies } = useIntakePolicies();

  useEffect(() => {
    fetchIntakePolicies().then(() => {
      if (intake) {
        setValue('policies', intake.policies || []);
      }
    });
  }, [intake, setValue]);

  const filteredPolicies = useMemo(
    () =>
      Object.groupBy(
        policies?.filter(pol => {
          if (searchString) {
            return pol.name.toLowerCase().includes(searchString.toLowerCase());
          }
          return true;
        }) ?? [],
        ({ category }) => category
      ),
    [policies, searchString]
  );

  const categories = useMemo(
    () => Object.keys(filteredPolicies),
    [filteredPolicies]
  );

  return (
    <>
      {/* <h4>Security Policies</h4>
      <Row className="g-4 mb-3">
        <p className="subheader">
          Please review and check all applicable policies that are currently
          implemented within your organization.
        </p>
      </Row> */}

      <div className="d-flex align-items-center mb-2">
        <FontAwesomeIcon
          style={{ color: '#2C7BE5' }}
          icon={faShieldAlt}
          size="2x"
          className="me-3 mb-3"
        />
        <div>
          <h4 className="mb-0">Security Policies</h4>
          <p className="subheader">
            Please review and check all applicable policies that are currently
            implemented within your organization.
          </p>
        </div>
      </div>

      {isLoading && (
        <Spinner animation="border" size="md" role="Status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
      <Row>
        <Col sm={6} md={5} lg={4}>
          <InputGroup className="mb-3">
            <Form.Control
              type="text"
              placeholder="Search policies"
              aria-label="Search policies"
              value={searchString}
              onChange={e => setSearchString(e.target.value)}
            />
          </InputGroup>
        </Col>
      </Row>
      <Card>
        <Card.Body>
          <Controller
            name="policies"
            control={control}
            defaultValue={intake?.policies || []}
            render={({ field }) => {
              return (
                <Accordion defaultActiveKey={categories} alwaysOpen>
                  <Accordion.Item
                    key="total"
                    eventKey="total"
                    style={{
                      '--falcon-accordion-btn-icon-width': '0px',
                      '--falcon-accordion-btn-icon-height': '0px',
                      '--falcon-accordion-btn-icon': 'none'
                    }}
                  >
                    <Accordion.Header>
                      <span className='dropdown-title'>Total{' '}</span>
                      <Badge
                        pill
                        bg={field.value.length > 0 ? 'primary' : 'secondary'}
                        style={{ marginLeft: '1rem' }}
                      >
                        {field.value.length} selected
                      </Badge>
                    </Accordion.Header>
                  </Accordion.Item>
                  {categories.map(cat => {
                    const selectedPoliciesInCategory = field.value.filter(pol =>
                      filteredPolicies[cat].map(pol => pol.id).includes(pol)
                    );
                    return (
                      <Accordion.Item key={cat} eventKey={cat}>
                        <Accordion.Header>
                          <span className='dropdown-title'>{cat}{' '}</span>
                          <Badge
                            pill
                            bg={
                              selectedPoliciesInCategory.length > 0
                                ? 'primary'
                                : 'secondary'
                            }
                            style={{ marginLeft: '1rem' }}
                          >
                            {selectedPoliciesInCategory.length} selected
                          </Badge>
                        </Accordion.Header>
                        <Accordion.Body>
                          <Table responsive="sm" style={{ fontSize: '13px' }}>
                            <thead>
                              <tr>
                                <th>
                                  <CheckWithIndeterminate
                                    checked={
                                      Array.isArray(field.value) &&
                                      selectedPoliciesInCategory.length ===
                                        filteredPolicies[cat].length
                                    }
                                    indeterminate={
                                      Array.isArray(field.value) &&
                                      selectedPoliciesInCategory.length > 0 &&
                                      selectedPoliciesInCategory.length <
                                        filteredPolicies[cat].length
                                    }
                                    onChange={e => {
                                      if (e.target.checked) {
                                        field.onChange(
                                          [
                                            ...field.value,
                                            ...filteredPolicies[cat].map(
                                              pol => pol.id
                                            )
                                          ].filter(arrayUnique)
                                        );
                                      } else {
                                        field.onChange(
                                          field.value.filter(
                                            id =>
                                              !filteredPolicies[cat]
                                                .map(pol => pol.id)
                                                .includes(id)
                                          )
                                        );
                                      }
                                    }}
                                  />
                                </th>
                                <th>Policy Name</th>
                                <th>Purpose</th>
                                <th>Relevant Standards</th>
                              </tr>
                            </thead>
                            <tbody>
                              {filteredPolicies[cat].map(pol => (
                                <tr key={pol.id}>
                                  <td>
                                    <Form.Check
                                      type="checkbox"
                                      id={`pol-${pol.id}`}
                                      value={pol.id}
                                      checked={field.value.includes(pol.id)}
                                      onChange={e => {
                                        const { checked } = e.target;
                                        if (checked) {
                                          field.onChange(
                                            [...field.value, pol.id].filter(
                                              arrayUnique
                                            )
                                          );
                                        } else {
                                          field.onChange(
                                            field.value.filter(
                                              id => id !== pol.id
                                            )
                                          );
                                        }
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Form.Label
                                      htmlFor={`pol-${pol.id}`}
                                      style={{ color: 'var(--falcon-primary)' }}
                                    >
                                      {pol.name}
                                    </Form.Label>
                                  </td>
                                  <td>{pol.purpose}</td>
                                  <td>{pol.standards}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              );
            }}
          />
        </Card.Body>
      </Card>
    </>
  );
};

PoliciesForm.propTypes = {
  control: PropTypes.object,
  intake: PropTypes.object,
  setValue: PropTypes.func.isRequired
};

export default PoliciesForm;
