import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const CheckWithIndeterminate = ({ checked, indeterminate, onChange }) => {
  const checkboxRef = useRef();

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  return (
    <Form.Check
      type="checkbox"
      ref={checkboxRef}
      checked={checked}
      onChange={onChange}
    />
  );
};

CheckWithIndeterminate.propTypes = {
  checked: PropTypes.bool.isRequired,
  indeterminate: PropTypes.bool.isRequired,
  onChange: PropTypes.func
};

export default CheckWithIndeterminate;
