import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import { Button, Card, Col, Row } from 'react-bootstrap';
import ConfidentialityBreachChart from './ConfidentialityBreachChart';
import classNames from 'classnames';
import FalconCardHeader from 'components/common/FalconCardHeader';
import lostBusiness from 'assets/img/icons/circle-cyan.svg';
import detectionEscalation from 'assets/img/icons/circle-blue.svg';
import notification from 'assets/img/icons/circle-light-blue.svg';
import exPost from 'assets/img/icons/circle-orange.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

const LeadItem = ({ item, isLast }) => {
  return (
    <>
      <Flex justifyContent="between" alignItems="center" className="pt-1">
        <Flex>
          <img
            src={item.img}
            alt="..."
            width={16}
            height={16}
            className="me-2"
          />
          <h6 className="text-700 mb-0 fw-bold">{item.title}</h6>
        </Flex>
        <h6 className="fs-10 text-500 mb-0 fw-bold">{item.target}</h6>
      </Flex>
      <Flex
        justifyContent="between"
        alignItems="center"
        className={classNames('border-bottom py-1', {
          'border-bottom-0 pb-0': isLast
        })}
        dangerouslySetInnerHTML={{ __html: item.toolTip }}
      ></Flex>
    </>
  );
};

const ConfidentialityBreach = ({ data }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const breachData = [
    {
      id: 1,
      title: 'Business Disruption',
      target: `${data[0] ? `$${new Intl.NumberFormat().format(data[0].value)}` : `$0`
        }
    `,
      img: lostBusiness,
      toolTip: `<ul>
      <li><h6 class="text-700 mb-0 pb-0">Business disruption & revenue loss from system downtime</h6></li>
      <li><h6 class="text-700 mb-0 pb-0">Cost of lost customers & customer acquisition</h6></li>
      <li><h6 class="text-700 mb-0 pb-0">Reputation loss & diminished goodwill</h6></li>
    </ul>`
    },
    {
      id: 2,
      title: 'Detection & Escalation',
      target: `${data[1] ? `$${new Intl.NumberFormat().format(data[1].value)}` : `$0`
        }
    `,
      img: detectionEscalation,
      toolTip: `<ul>
      <li><h6 class="text-700 mb-0 pb-0">Forensic & Investigative cost</h6></li>
      <li><h6 class="text-700 mb-0 pb-0">Cost of Audit & Assessment</h6></li>
      <li><h6 class="text-700 mb-0 pb-0">Crisis Management</h6></li>
    </ul>`
    },
    {
      id: 3,
      title: 'Notification',
      target: `${data[2] ? `$${new Intl.NumberFormat().format(data[2].value)}` : `$0`
        }
    `,
      img: notification,
      toolTip: `<ul>
      <li><h6 class="text-700 mb-0 pb-0">Emails, letters, outbound calls or other notices</h6></li>
      <li><h6 class="text-700 mb-0 pb-0">Determination of regulatory requirements</h6></li>
      <li><h6 class="text-700 mb-0 pb-0">Communication of regulators</h6></li>
      <li><h6 class="text-700 mb-0 pb-0">PR consultants</h6></li>
    </ul>`
    },
    {
      id: 4,
      title: 'Ex-Post',
      target: `${data[3] ? `$${new Intl.NumberFormat().format(data[3].value)}` : `$0`
        }
    `,
      img: exPost,
      toolTip: `<ul>
      <li><h6 class="text-700 mb-0 pb-0">Help desk & inbound communications</h6></li>
      <li><h6 class="text-700 mb-0 pb-0">Credit monitoring & identity protection</h6></li>
      <li><h6 class="text-700 mb-0 pb-0">Issuing new accounts or credit cards</h6></li>
      <li><h6 class="text-700 mb-0 pb-0">Legal fees & defense costs</h6></li>
      <li><h6 class="text-700 mb-0 pb-0">Product discounts</h6></li>
      <li><h6 class="text-700 mb-0 pb-0">Regulatory fines & penalties</h6></li>
    <ul>`
    }
  ];
  return (
    <Card className="h-100">
      <FalconCardHeader
        title="Breach Impact"
        titleTag="h6"
        className="border-200 border-bottom py-2 mt-2"
      />
      <Card.Body as={Row}>
        {/* TKLA - add conditional class based on if xxl breakpoint or below. when  <= md mt=8 */}
        <Col lg={12} md={5} className="mb-xxl-1">
          <ConfidentialityBreachChart data={data} />
        </Col>
        <Col lg={12} md={7}>
          {/* <hr className="mx-ncard d-md-none d-xxl-block" /> */}
          <Button 
            variant="falcon-default" onClick={toggleExpand} className="w-100 mt-1"
          >
            {isExpanded ? 'Collapse' : 'Expand'} Cost Breakdown <FontAwesomeIcon className='ps-1' icon={isExpanded ? faCaretUp : faCaretDown} />
          </Button>
          {isExpanded && (
          <div className='mt-3'>
            {breachData.map((item, index) => (
              <div key={item.id} style={{ marginBottom: '0.5rem' }}>
                <LeadItem
                  item={item}
                  isLast={index === breachData.length - 1}
                />
              </div>
            ))}
          </div>
        )}
        </Col>
      </Card.Body>
    </Card>
  );
};

LeadItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    toolTip: PropTypes.string.isRequired
  }),
  isLast: PropTypes.bool.isRequired
};

ConfidentialityBreach.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
};

export default ConfidentialityBreach;
