import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import useGetOneClient from 'hooks/useGetOneClient';
import useIntake from 'hooks/useIntake';
import Avatar from 'components/common/Avatar';
import ClientWidjets from './ClientWidjets';
import useAccount from 'hooks/useAccount';
import ConfirmProspectConvert from 'components/common/ConfirmProspectConvert';

import '../../../assets/scss/theme/page-styles/_clientDetails.scss';
import IconButton from 'components/common/IconButton';
import { faBolt, faBook, faBookMedical, faBuilding, faBus, faCalendarAlt, faCircle, faCoins, faComments, faFilm, faGlobe, 
        faHeartbeat, faHome, faIndustry, faMapPin, faMicrochip, faMicroscope, faSchool, faShoppingCart, faStore, faTools
} from '@fortawesome/free-solid-svg-icons';

const industryIconMap = {
  Education: faBook,
  Financial: faCoins,
  Healthcare: faHeartbeat,
  Energy: faBolt,
  Pharmaceuticals: faBookMedical,
  Industrial: faIndustry,
  Technology: faMicrochip,
  Services: faTools,
  Entertainment: faFilm,
  Transportation: faBus,
  Communication: faComments,
  Consumer: faShoppingCart,
  Media: faSchool,
  Hospitality: faBuilding,
  Retail: faStore,
  Research: faMicroscope,
  Public: faHome,
  None: faHome
};

const getCompanySizeFromRecords = (estimatedRecordsNumber) => {
  if (estimatedRecordsNumber <= 1000) {
    return 'Small';
  } else if (estimatedRecordsNumber <= 10000) {
    return 'Medium-Small';
  } else if (estimatedRecordsNumber <= 100000) {
    return 'Medium';
  } else if (estimatedRecordsNumber <= 1000000) {
    return 'Medium-Large';
  } else if (estimatedRecordsNumber <= 10000000) {
    return 'Large';
  } else if (estimatedRecordsNumber <= 100000000) {
    return 'Very Large';
  } else {
    return 'Mega Corporations';
  }
};

const InfoCard = ({ client, recordsEstimate }) => {
  const { returnAccount } = useAccount();
  const [userInfo, setUserInfo] = useState(null);
  const navigate = useNavigate();
  const prospect = client.prospect;
  const creationDate = new Date(client.creationDate);
  const formattedDate = creationDate.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });

  const goToUserSettings = () => {
    navigate(`/clients/update/${client.id}`);
  };

  useEffect(() => {
    const loadUserInfo = async () => {
      const thisUser = await returnAccount();
      setUserInfo(thisUser);
    };

    loadUserInfo();
  }, [userInfo]);

  return (
    <Card className="client-details-card mt-3">
      <Card.Body className="client-details-card-body">
        {/* Header */}
        <div
          className={`client-details-card-header d-flex justify-content-between align-items-center`} //${prospect ? 'header-color-for-prospect' : 'header-color-for-client'}
        >
          <div>
            <Card.Title style={{ fontSize: '2.5rem' }}>{client.name}</Card.Title>
            <Card.Text className='mt-2'>
              <FontAwesomeIcon 
                icon={faCircle}
                color={prospect ? '#D9E7FA ' : '#00894F'}
                className='me-2'
              />
              Account Type: <strong style={{ fontWeight: 'bolder' }}>{prospect ? 'Prospect' : 'Client'}</strong>
              {/* <span className="since-text-style">
                {prospect ? 'Prospect since: ' : 'Client since: '}
                {formattedDate}
              </span> */}
            </Card.Text>
          </div>
        </div>
                
        {/* Content */}
        <Container>
          <Row>
            <Col md={6} xs={12} className='border-end border-2 org-info-card'>
              <p> Estimated Organization Size: 
                <strong style={{ fontWeight: 'bolder' }}>
                  {recordsEstimate ? ` ${getCompanySizeFromRecords(recordsEstimate)}` : ' N/A'}
                </strong>
              </p>
              <p>Sensitive Data Count: 
                <strong style={{ fontWeight: 'bolder' }}>
                  {recordsEstimate ? ` ${recordsEstimate}` : ' N/A'}
                </strong>
              </p>
              <Row className='mt-3'>
                <Col md={6} xs={12}>
                  <p>
                    <FontAwesomeIcon 
                      icon={faCalendarAlt}
                      className='me-3'
                    />
                    Joined {formattedDate}
                  </p>
                  <p className='mt-2 mb-2'>
                    <FontAwesomeIcon 
                      icon={faGlobe}
                      style={{ marginRight: '0.75rem'}}
                    />
                    {client?.website ? client.website : 'N/A'}
                  </p>
                </Col>
                <Col md={6} xs={12}>
                <p>
                    <FontAwesomeIcon 
                      icon={industryIconMap[client.industry]}
                      className='me-2'
                    />
                    {client.industry}
                  </p>
                  <p className='mt-2'>
                    <FontAwesomeIcon 
                      icon={faMapPin}
                      className='me-2 ms-1'
                    />
                    {client.country}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col md={6} xs={12}>
              {userInfo?.isAdmin && (
                <div className='edit-general-info-button'>
                    <IconButton
                    className="edit-button"
                    variant="falcon-default"
                    size="sm"
                    icon="pencil-alt"
                    onClick={goToUserSettings}
                  >
                    <span className="ms-1">Edit General Info</span>
                  </IconButton>
                </div>
              )}
              <div style={{ marginLeft: '2rem' }}>
                <h5 className="basic-info-contact-header">Primary Contact</h5>
                <div className="primary-contact-info-container">
                  {client.contacts[0] ? (
                    <>
                      <Avatar
                        size="3xl"
                        className="primary-contact-icon"
                        name={client.contacts[0].name}
                      />
                      <div className="primary-contact-info-details">
                        <div className="fw-bold">{client.contacts[0].name}</div>
                        <div className="">
                          <FontAwesomeIcon
                            style={{ marginRight: '0.5rem' }}
                            icon="envelope"
                          />{' '}
                          {client.contacts[0].contactMethod === 'email' ? (
                            <>
                              <a href={`mailto:${client.contacts[0].email}`}>
                                {client.contacts[0].email}
                                <FontAwesomeIcon
                                  style={{ marginLeft: '0.5rem' }}
                                  icon="star"
                                />
                              </a>
                            </>
                          ) : (
                            client.contacts[0].email
                          )}
                        </div>
                        <div className="">
                          <FontAwesomeIcon
                            style={{ marginRight: '0.5rem' }}
                            icon="phone"
                          />{' '}
                          {client.contacts[0].contactMethod === 'phone' ? (
                            <>
                              <a href={`tel:${client.contacts[0].phone}`}>
                                {client.contacts[0].phone}
                                <FontAwesomeIcon
                                  style={{ marginLeft: '0.5rem' }}
                                  icon="star"
                                />
                              </a>
                            </>
                          ) : (
                            client.contacts[0].phone
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>No info available</p>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        {/* Icon */}
        {/* <div className='client-industry-icon-div'>
                    <EnergyIndustryIcon className={`client-industry-icon industry-outer-circle industry-path-1 industry-path-2 ${prospect ? 'prospect-icon' : 'client-icon'}`}/>
                </div> */}

      </Card.Body>
    </Card>
  );
};

InfoCard.propTypes = {
  client: PropTypes.object.isRequired
};

const ProspectConvert = ({ clientId }) => {
  const clientLimit = localStorage.getItem('clientLimit');

  const navigate = useNavigate();
  const { upgradeClient } = useGetOneClient();
  const [showModal, setShowModal] = useState(false);

  const handleConvertToClient = () => {
    upgradeClient(clientId)
      .then(updatedClient => {
        // Handle successful update (e.g., show a success message, redirect, etc.)
      })
      .catch(error => {
        console.error('Error updating client:', error);
        // Handle error (e.g., show an error message)
      });

    navigate(`/clients`);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const showPopup = () => {
    setShowModal(true);
  }

  return (
    <Card className="mt-3">
      <Card.Body className="text-center d-flex flex-column justify-content-center align-items-center">
        {clientLimit === 'true' ? (
          <>
            <div className="fw-bolder">
              You have reached your limit of how many Clients you may have.
              Please upgrade your plan to convert this Prospect
            </div>
          </>
        ) : (
          <>
            <div className="fw-bolder">
              Convert from a Prospect to a Client to unlock all features,
              including easy report access and controls!
            </div>
            <Button
              className="mt-3"
              variant="success"
              onClick={showPopup}
            >
              Convert To Client
            </Button>
          </>
        )}
      </Card.Body>
      <ConfirmProspectConvert 
        show={showModal}
        onClose={handleClose}
        onConfirm={handleConvertToClient}
        message={'Are you sure you want to convert this prospect into a client?'}
      />
    </Card>
  );
};

ProspectConvert.propTypes = {
  clientId: PropTypes.string.isRequired
};

const ClientDetails = () => {
  const { clientId } = useParams();
  const [client, setClient] = useState(null);
  const [intake, setIntake] = useState(null);
  const [estimatedRecords, setEstimatedRecords] = useState(null);
  const { getClient } = useGetOneClient();
  const { fetchIntakeForClient } = useIntake();
  const navigate = useNavigate();

  const goToIntakeForm = () => {
    navigate(`/clients/${client.id}/intake`);
  };

  useEffect(() => {
    const getClientDetails = async clientId => {
      try {
        const clientDetail = await getClient(clientId);

        getIntakeDetails();

        if (clientDetail.prospect) {
          setIntake(null);
        }

        setClient(clientDetail);
      } catch (error) {
        throw new Error(error.message);
      }
    };

    const getIntakeDetails = async () => {
      try {
        const clientIntake = await fetchIntakeForClient(clientId);

        setEstimatedRecords(clientIntake.recordsEstimate);
        setIntake(clientIntake);
      } catch (error) {
        throw new Error(error.message);
      }
    };

    localStorage.setItem('clientId', clientId);
    getClientDetails(clientId);
  }, []);

  const backToOverview = () => {
    navigate(-1);
  };

  if (!client) return <div>Loading...</div>;

  return (
    <>
      <div className="mt-3 fw-bold">
        <a onClick={backToOverview} className="return-to-overview">
          <FontAwesomeIcon icon="chevron-left" className="me-3" />
          Back to Clients
        </a>
      </div>
      <InfoCard client={client} recordsEstimate={estimatedRecords}/>
      {client.prospect ? <ProspectConvert clientId={clientId} /> : <></>}
      <ClientWidjets
        client={client}
        intake={intake}
        goToIntakeForm={goToIntakeForm}
      />
    </>
  );
};

export default ClientDetails;
