import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import useGetClient from 'hooks/useGetClients';
import { Link, useNavigate } from 'react-router-dom';
import paths, { rootPaths } from 'routes/paths';

import thumbnail1 from '../../assets/img/thumbnails/Thumbnail - Guide 1.jpg';
import thumbnail2 from '../../assets/img/thumbnails/Thumbnail - Guide 2.jpg';
import thumbnail3 from '../../assets/img/thumbnails/Thumbnail - Guide 3.jpg';
import thumbnail4 from '../../assets/img/thumbnails/Thumbnail - Guide 4.jpg';
import thumbnail5 from '../../assets/img/thumbnails/Thumbnail - Guide 5.jpg';
import thumbnail6 from '../../assets/img/thumbnails/Thumbnail - Guide 6.jpg';
import thumbnail7 from '../../assets/img/thumbnails/Thumbnail - Guide 7.jpg';
import thumbnail8 from '../../assets/img/thumbnails/Thumbnail - Guide 8.jpg';
import thumbnail9 from '../../assets/img/thumbnails/Thumbnail - Guide 9.jpg';
import thumbnail10 from '../../assets/img/thumbnails/Thumbnail - Guide 10.jpg';
import thumbnail11 from '../../assets/img/thumbnails/Thumbnail - Case Study.jpg';
import thumbnail12 from '../../assets/img/thumbnails/Thumbnail - Verizon DBIR.jpg';

import '../../assets/scss/theme/page-styles/_threatAcademy.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faSearch, faTable, faList, faPlay, faAward
} from '@fortawesome/free-solid-svg-icons';

const Academy = () => {
    const navigate = useNavigate();

    const [activeButton, setActiveButton] = useState('button1'); // State to track active button
    const [isGridView, setIsGridView] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [clickedIndex, setClickedIndex] = useState(null);
    const [linkToOpen, setLinkToOpen] = useState('');

    const files = [
        {title: 'Using ThreatCaptain for MSP Success', author: 'Captain Adam', thumbnail: thumbnail1, 
            link: '/files/Academy1.pdf',
            description: 'Master cyber risk communication to enhance your MSP success. Learn to leverage ThreatCaptain\'s data-driven breach cost estimates to articulate financial implications, build client trust, and justify cybersecurity investments.'},
        {title: 'MSP Sales process Using ThreatCaptain', author: 'Captain Adam', thumbnail: thumbnail2, 
            link: '/files/Academy2.pdf',
            description: 'Streamline your sales process by translating cyber risks into financial impacts. Discover how to effectively communicate cybersecurity value, leading to increased client buy-in and stronger relationships.'},
        {title: 'Communicating Cyber Risk in Business Terms with ThreatCaptain', author: 'Captain Adam', thumbnail: thumbnail3, 
            link: '/files/Academy3.pdf',
            description: 'Learn to convey the significance of cybersecurity investments in business language. Understand how to translate technical threats into clear financial terms, empowering executives to make informed decisions on breach costs.'},
        {title: 'Building Trust and Transparency with ThreatCaptain', author: 'Captain Adam', thumbnail: thumbnail4, 
            link: '/files/Academy4.pdf',
            description: 'Learn to foster trust with clients by using ThreatCaptain to deliver data-driven financial estimates of cyber threats. Understanding vulnerabilities and quantifying risks is crucial for building long-term client relationships.'},
        {title: 'Boosting Cybersecurity Sales with ThreatCaptain', author: 'Captain Adam', thumbnail: thumbnail5, 
            link: '/files/Academy5.pdf',
            description: 'Master the art of showcasing your services\' value with ThreatCaptain\'s financial simulations. Understanding how to illustrate potential losses and mitigation strategies is essential for justifying cybersecurity investments.'},
        {title: 'Integrating Financial Impact Estimates into Quarterly Business Reviews (QBRs)', author: 'Captain Adam', thumbnail: thumbnail6, 
            link: '/files/Academy6.pdf',
            description: 'Learn to enhance QBRs by integrating ThreatCaptain’s financial impact estimates, focusing discussions on reducing financial risk to strengthen client relationships and position yourself as a trusted advisor.'},
        {title: 'Leveraging MITRE ATT&CK with ThreatCaptain to Quantify Risk Reduction', author: 'Captain Adam', thumbnail: thumbnail7, 
            link: '/files/Academy7.pdf',
            description: 'Gain insights into using the MITRE ATT&CK framework to demonstrate how specific security controls disrupt cyber threats, quantifying breach impacts to effectively communicate the value of cybersecurity investments.'},
        {title: 'Overcoming Objections with Financial Data Using ThreatCaptain', author: 'Captain Adam', thumbnail: thumbnail8, 
            link: '/files/Academy8.pdf',
            description: 'Learn to address client objections about cybersecurity investments with ThreatCaptain\'s financial data. Understanding how to present potential incident costs helps position cybersecurity as a crucial business necessity.'},
        {title: 'Building a Cybersecurity Roadmap: Prioritizing Financial Risk Reduction', author: 'Captain Adam', thumbnail: thumbnail9, 
            link: '/files/Academy9.pdf',
            description: 'Understand how to create a cybersecurity roadmap that prioritizes investments based on financial risk reduction, focusing on the most harmful threats to maximize client protection.'},
        {title: 'Enhancing Tabletop Exercises and Training with Financial Impact Analysis', author: 'Captain Adam', thumbnail: thumbnail10, 
            link: '/files/Academy10.pdf',
            description: 'Learn to add financial impact analysis to tabletop exercises, quantifying potential losses from incidents to enhance training and preparedness for cyber incidents.'},
        {title: 'MSP Offering vCISO Services Using ThreatCaptain', author: 'Captain Adam', thumbnail: thumbnail11, 
            link: '/files/Academy11.pdf',
            description: 'Discover how to effectively guide clients in risk management as a vCISO, using ThreatCaptain to prioritize security investments and assess the financial impact of vulnerabilities.'},
        {title: 'Introduction to the Verizon Data Breach Investigations Report (DBIR)', author: 'Captain Adam', thumbnail: thumbnail12, 
            link: '/files/Academy12.pdf',
            description: 'Learn to leverage insights from the Verizon DBIR to understand the cybersecurity threat landscape, guiding clients in risk management and informed security investments.'},
    ];

    useEffect(() => {
        if (linkToOpen) {
            window.open(linkToOpen, '_blank');
            setLinkToOpen('');
        }
    }, [linkToOpen])

    const handleToggle = (button) => {
        setActiveButton(activeButton === button ? null : button); // Toggle the active button
        setIsGridView(!isGridView);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredItems = files.filter((item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleDocumentClick = (index, link) => {
        setLinkToOpen(link);
        setClickedIndex(index);
    }

    const handleOpenCertificate = (courseName) => {
        const newTab = window.open(`/certificatePage?courseName=${encodeURIComponent(courseName)}`, '_blank');
        if (newTab) newTab.focus(); // Focus the new tab
    }

    return (
    <>
        <Card className='m-1 mb-3 p-2 ps-3 pt-3 pb-3'>
            <h5>All Courses</h5>
            <div className="pt-3 d-flex justify-content-between align-items-center">
                {/* Search Bar on the left */}
                <div className="search-bar position-relative">
                    <input
                        type="text"
                        className="form-control ps-5" // Add padding to the left for the icon
                        placeholder="Search..."
                        aria-label="Search"
                        style={{ borderColor: '#D8E2EF' }} // Custom border color
                        onChange={handleSearchChange}
                    />
                    <FontAwesomeIcon
                        icon={faSearch}
                        className="position-absolute"
                        style={{
                        left: '10px', // Position the icon inside the input
                        top: '50%',
                        transform: 'translateY(-50%)',
                        color: '#999',
                        }}
                    />
                </div>

                {/* Buttons on the right */}
                <div className="buttons d-flex align-items-center">
                    <span className="me-2"><strong>View:</strong></span>

                    <button
                        className={`btn mx-1 d-flex align-items-center justify-content-center ${activeButton === 'button1' ? 'active' : ''}`}
                        onClick={() => handleToggle('button1')}
                        style={{
                        backgroundColor: activeButton === 'button1' ? '#748194' : 'transparent', // Active background color
                        border: 'none', // Remove border
                        borderRadius: '5px', // Optional: rounded corners
                        width: '1rem', // Fixed width
                        height: '2rem', // Fixed height
                        color: activeButton === 'button1' ? '#FFFFFF' : '#748194', // Icon color when active
                        }}
                    >
                        <FontAwesomeIcon icon={faTable} />
                    </button>
                    <button
                        className={`btn mx-1 d-flex align-items-center justify-content-center ${activeButton === 'button2' ? 'active' : ''}`}
                        onClick={() => handleToggle('button2')}
                        style={{
                        backgroundColor: activeButton === 'button2' ? '#748194' : 'transparent', // Active background color
                        border: 'none', // Remove border
                        borderRadius: '5px', // Optional: rounded corners
                        width: '1rem', // Fixed width
                        height: '2rem', // Fixed height
                        color: activeButton === 'button2' ? '#FFFFFF' : '#748194', // Icon color when active
                        }}
                    >
                        <FontAwesomeIcon icon={faList} />
                    </button>
                </div>
            </div>
        </Card>
        <div className={isGridView ? 'grid-view' : 'list-view'}>
            {filteredItems.map((item, index) => (
                <Card key={index} className='mb-3'>
                    <Card.Body className={isGridView ? 'd-flex flex-column h-100' : ''}>
                        <Row className='flex-grow-1'> {/* Allow Row to grow */}
                            <Col md={isGridView ? 12 : 3}>
                                <img src={item.thumbnail} alt='Threat Captain' className='img-fluid'/>
                            </Col>
                            <Col md={isGridView ? 12 : 7}>
                                <h6 className='mt-2' style={{color: '#2C7BE5'}}>{item.author}</h6>
                                <h3>{index + 1}. {item.title}</h3>
                                <p>{item.description}</p>
                            </Col>
                            <Col md={isGridView ? 12 : 2} className={`d-flex flex-column ${isGridView ? 'mt-auto' : 'justify-content-center align-items-center'}`}>
                                <div className='d-flex flex-column'> {/* Wrap in a div for proper alignment */}
                                    <Button
                                        variant='falcon-primary'
                                        className='mt-2'
                                        style={{fontSize: '0.90rem'}}
                                        onClick={() => handleDocumentClick(index, item.link)}
                                    >
                                        <FontAwesomeIcon size='sm' icon={faPlay} className='me-1'/>
                                        View
                                    </Button>
                                    {clickedIndex === index && (
                                        <Button className='mt-2' variant='primary' style={{fontSize: '0.90rem'}} onClick={() => handleOpenCertificate(item.title)}>
                                            <FontAwesomeIcon size='sm' icon={faAward} className='me-1'/>
                                            Print Certificate
                                        </Button>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            ))}
        </div>
    </>
  );
};
export default Academy;
