import React, { useEffect, useState, useMemo } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { rootPaths } from 'routes/paths';

import WaitingCard from 'helpers/WaitingCard';

import Avatar from 'components/common/Avatar';
import PropTypes from 'prop-types';

import { timezones } from 'data/events/timezones';
import Select from 'react-select'; 

import 'react-toastify/dist/ReactToastify.css';

const timezoneOptions = timezones.map(tz => ({
  label: tz, 
  value: tz 
}));


const GeneralSettings = ({ userInfo, updateAccount }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadStatus, setLoadStatus] = useState('default');
  const { user } = useAuth0();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    timezone: ''
  });

  const formattedUserInfo = useMemo(() => {
    if (userInfo) {
      const response = {
        name: userInfo.name || '',
        email: userInfo.email || '',
        phoneNumber: userInfo.phoneNumber || '',
        timezone: userInfo.timezone || ''
      };

      if (response.name === response.email) {
        response.name = '';
      }

      return response;
    }
    return {};
  }, [userInfo]);

  const formIsDirty = useMemo(
    () =>
      Object.keys(formData).some(
        key => formData[key] !== formattedUserInfo[key]
      ),
    [formData, formattedUserInfo]
  );

  useEffect(() => {
    if (formattedUserInfo) {
      setFormData({
        name: formattedUserInfo.name,
        email: formattedUserInfo.email,
        phoneNumber: formattedUserInfo.phoneNumber,
        timezone: formattedUserInfo.timezone
      });
    }
  }, [formattedUserInfo]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSelectChange = selectedOption => {
    setFormData(prevData => ({
      ...prevData,
      timezone: selectedOption.value
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    //setIsLoading(true);
    
    const storeUpdates = async () => {
      const id = toast.loading('Storing Data...');
      try {
        const response = await updateAccount(formData);

        if (response === true) {
          toast.update(id, {
            render: 'Update Successful!',
            type: 'success',
            isLoading: false,
            autoClose: 5000 // Automatically close after 5 seconds
          });

          // setLoadStatus('success');
          // setTimeout(() => {
          //   setLoadStatus('default');
          //   setIsLoading(false);
          // }, 1500);

        } else {
          toast.update(id, {
            render: 'An error occurred. Please try again later.',
            type: 'error',
            isLoading: false,
            autoClose: 5000 // Automatically close after 5 seconds
          });

          // setLoadStatus('error');

          // setTimeout(() => {
          //   setLoadStatus('default');
          //   setIsLoading(false);
          // }, 5000);
        }
        
      } catch (error) {
        toast.update(id, {
          render: 'An error occurred. Please try again later.',
          type: 'error',
          isLoading: false,
          autoClose: 5000 // Automatically close after 5 seconds
        });

        // setLoadStatus('error');

        //   setTimeout(() => {
        //     setLoadStatus('default');
        //     setIsLoading(false);
        //   }, 5000);
      }
    };

    storeUpdates();
  };

  if (!userInfo) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {isLoading && <WaitingCard type={'user'} status={loadStatus}/>}
      <ToastContainer />
      <div className="general-settings-top-section">
        {userInfo.isAdmin ? <p>Admin</p> : <p>User</p>}
        {user?.picture ? (
          <Avatar size="3xl" src={user.picture} />
        ) : (
          <Avatar size="3xl" name={userInfo.name} />
        )}
      </div>
      <Form onSubmit={handleSubmit} className="account-general-details-form">
        <Row>
          <Col md={6}>
            <Form.Group controlId="formName">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginTop: '1rem' }}>
          <Col md={6}>
            <Form.Group controlId="formPhone">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <label htmlFor="timezone" className="form-label">Timezone</label>
              <Select
                options={timezoneOptions}
                onChange={handleSelectChange}
                value={timezoneOptions.find(option => option.value === formData.timezone)}
                classNamePrefix="react-select"
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-4 justify-content align-items-center">
          <Col xs="auto">
            <Button variant="primary" type="submit" disabled={!formIsDirty}>
              Save Profile
            </Button>
          </Col>
          <Col xs="auto">
            <Link
              className="cancel"
              to={rootPaths.root}
              style={{ color: 'black' }}
            >
              Cancel
            </Link>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

GeneralSettings.propTypes = {
  userInfo: PropTypes.object,
  updateAccount: PropTypes.func
};

export default GeneralSettings;
