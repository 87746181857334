import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';
import IntakeHeader from './IntakeHeader';
import Wizard from './ClientIntakeWizard/Wizard';

const ClientIntake = ({ operation }) => {
  return (
    <>
      <Row className="g-3">
        <IntakeHeader />
      </Row>
      <Row className="g-3">
        <Wizard validation={true} operation={operation} />
      </Row>
    </>
  );
};

const AddClient = () => {
  return <ClientIntake operation="add" />;
};

const UpdateClient = () => {
  return <ClientIntake operation="update" />;
};

ClientIntake.propTypes = {
  operation: PropTypes.oneOf(['add', 'update'])
};

export { ClientIntake, AddClient, UpdateClient };
