import React, { useRef, useEffect, useState } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import '../assets/scss/theme/page-styles/_certificatePage.scss';
import { useLocation } from 'react-router-dom';

import useAccount from 'hooks/useAccount';
import { Card } from 'react-bootstrap';
import signatures from '../assets/img/threat-captain-logos/signatures.png';


const certificatePage =() => {
    const certificateRef = useRef();
    const location = useLocation();
    const { returnAccount } = useAccount();

    const [userInfo, setUserInfo] = useState(null);

    const getQueryParams = () => {
        const params = new URLSearchParams(location.search);
        return {
            courseName: params.get('courseName') || '',
        };
    }

    const { courseName } = getQueryParams();

    useEffect(() => {
        const loadUserInfo = async () => {
            const user = await returnAccount();
            setUserInfo(user);
          };

          loadUserInfo();
    }, []);

    useEffect(() => {
        if (userInfo) {
            // Generate the PDF once the page loads
            const generatePDF = () => {
                const input = certificateRef.current;

                const inputWidth = input.offsetWidth;
                const inputHeight = input.offsetHeight;

                const pdf = new jsPDF('landscape', 'pt', [inputWidth, inputHeight]);

                html2canvas(input, { scale: 2 }).then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');

                    // Calculate the width and height for the PDF page to match the aspect ratio
                    const imgWidth = pdf.internal.pageSize.getWidth();
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;

                    // Add the image to the PDF
                    pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

                    pdf.save('Certificate.pdf');
                });
            };

            generatePDF();
        }
    }, [userInfo]);

    if (!userInfo) {
        return <div>Loading...</div>;
    }

    return (
        <Card className='certificate-background' ref={certificateRef}>
            <div className="certificate-container">
                <div>
                    <h1 className='certificate-top-text'>CERTIFICATE</h1>
                    <h3 className='certificate-second-text'>OF COMPLETION</h3>
                    <h5 className='certificate-third-text'>Proudly Presented To</h5>
                    <h2 className='certificate-user-text'>{userInfo.name}</h2>
                    <h4 className='certificate-course-text'>For completing "{courseName}" from Threat Academy</h4>
                </div>
                <div style={{ paddingTop: '4rem' }}>
                    <img src={signatures} />
                </div>
            </div>
        </Card>
    );
};

export default certificatePage;